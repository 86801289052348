import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config/config';


@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  constructor(private http: HttpClient) {}

  googleAuth(){
    return this.http.get(Config.backend_url+'/google/auth')
  }

  verifyUser(data: any){
    return this.http.post(Config.backend_url+`/google`, data)
  }

  updateOauth(data: any){
    return this.http.put(Config.backend_url+'/google', data)
  }

  getOAuthUser(){
    return this.http.get(Config.backend_url+'/google')
  }
  createDrive(account_id:string, folder_id:string){
    return this.http.post(Config.backend_url+`/drive/files/${encodeURIComponent(account_id)}/${encodeURIComponent(folder_id)}`, {})
  }

  getDrive(){
    return this.http.get(Config.backend_url+`/drive`)
  }

  getDriveAccounts(){
    return this.http.get(Config.backend_url+`/drive/accounts`)
  }

}
