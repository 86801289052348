<div>
    <div class="container mt-3">
        <div class="row">
            <div class="card col-md-6">
                <div class="card-header">
                    Woo Commerce
                </div>
                <div class="card-body">
                    <form [formGroup]="woocomForm" (ngSubmit)="createWoocom()">
                        <h5 class="card-title">Add Details</h5>
                        <div>
                            <label for="api_key">API Key:</label>
                            <input type="text" id="api_key" class="form-control form-control-sm" placeholder="Api Key"
                                formControlName="api_key" required>
                        </div>
                        <div>
                            <label for="api_secret">API Secret:</label>
                            <input type="text" id="api_secret" class="form-control form-control-sm"
                                placeholder="Api Secret" formControlName="api_secret">
                        </div>
                        <label for="shopName">Shop Name:</label>
                        <input type="text" id="shopName" class="form-control form-control-sm" placeholder="Shop Name"
                            formControlName="shopName">
                        <div>
                            <label for="start_date">Start Date:</label>
                            <input type="date" id="start_date" class="form-control form-control-sm"
                                formControlName="startDate">
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row p-2">
                <div class="col-md-12" *ngIf="woocomData">
                    <h4></h4>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Job Id</th>
                                <th scope="col">Shop</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Last Sync Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sd of woocomData">
                                <td>{{sd.job_sync.jobId}}</td>
                                <td>{{sd.shop}}</td>
                                <td>{{sd.createdOn | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                                <td>{{sd.job_sync.jobStatus}}</td>
                                <td>{{sd.job_sync.lastUpdatedAt | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                                <td>
                                    <i class="bi bi-arrow-clockwise p-2" placement="bottom" ngbTooltip="Synchronize"
                                        (click)="syncJob(sd)"></i>
                                    <i class="bi bi-info-circle p-2" placement="bottom" ngbTooltip="Details"
                                        (click)="redirectToJobHistory(sd)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>