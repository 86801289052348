import { Injectable } from '@angular/core';
import { Subject, Observable, throwError, of } from 'rxjs';
import { map, takeUntil, catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../config/config';
import { XSTable } from 'src/app/pages/spreadsheet/spreadsheet.model';
import { AIRequestResponse, AIChatMessage,  AITargetChange, SheetReference, AISheetChange, AIError, AIEventType, AIEvent } from 'src/app/pages/aichat/aichat.model'
import { StateObservable, AIChatObservable, ActiveSheetObservable, VisibilityObservable } from './observable.service';
import { ToastService } from 'src/app/toast/toast.service';
import { WebsocketService } from 'src/app/core/service/websocket.service';
import { SoundService } from 'src/app/core/service/sound.service';

@Injectable({
    providedIn: 'root',
})
export class AIChatService {

    private cancelRequestSubject = new Subject<void>();

    private conversation:AIChatMessage[] = [];

    readonly iConversationLength:number = 5;

    constructor(
        private http: HttpClient,
        private stateObservable: StateObservable,
        private aiChatObservable: AIChatObservable,
        private activeSheetObservable: ActiveSheetObservable,
        private toastService: ToastService,
        private websocketService: WebsocketService,
        private visibilityObservable: VisibilityObservable,
        private soundService: SoundService
    ) { }

    private showSuccess(target:AITargetChange) {
        const title:string = 'Request Complete';
        let message:string = '';
        if (target.isNewTable) {
            message = `A new table '${target.tableName}' has been created.`;
        } else if (target.isNewSheet) {
            message = `A new sheet '${target.sheetName}' has been created.`;
        } else {
            message = `Sheet '${target.sheetName}' has been modified.`
        }
        this.toastService.showSuccess(title,message);
    }

    private showError(error:AIError) {
        this.toastService.showError(error.title,error.message);
    }

    private propagateAIEvent(type: AIEventType, sheetRef?:SheetReference, data?:any ) {
        const event:AIEvent = { ...sheetRef , type, data };
        this.aiChatObservable.registerNewUpdate(event);
    }
    
    private addToConversation(elem:AIChatMessage) {
        this.conversation.push(elem);
    }

    private removeLastUserEntryFromConversation() {
        if (this.conversation.length && !this.conversation[this.conversation.length-1].isAI) {
            this.conversation.splice(-1);
        }
    }

    getConversation() {
        return this.conversation;
    }

    // Helper function, compares identities of two sheet references
    private is(currentSheet:SheetReference,comparisonSheet:SheetReference): boolean {
        if (!currentSheet || !comparisonSheet) return false;
        return  currentSheet.sheetId == comparisonSheet.sheetId
            &&  currentSheet.tableId == comparisonSheet.tableId;
    }

    fetchImage(url: string): Observable<Blob> {
        return this.http.get(url, { responseType: 'blob' });
    }

    transcribe(audio:Blob): Observable<string> {
        this.stateObservable.setLoading(true);
        const requestURI = Config.backend_url + '/ai/transcribe';
        const formData: any = new FormData();
        formData.append('file', audio);
        
        return this.http.post<{query:string;}>(requestURI, formData).pipe(
            map(res => {
              if (res) {
                console.log(`Transcription Result:`,res);
                return res.query;
              }
              return res;
            }),
            catchError(error => {
              console.error('Error during HTTP request:', error);
              return throwError(error);
            }),
            finalize(() => {
              this.stateObservable.setLoading(false);
            })
        );
    }

    request(query: string): Observable<any> {
        this.stateObservable.setLoading(true);
    
        const requestURI = Config.backend_url + '/ai/ask';
        const requestBody = { query };
    
        return this.websocketService.connect().pipe(
            switchMap(socketId => {
                if (!socketId) {
                    console.log("WebSocket connection failed or no socketId received.");
                    this.stateObservable.setLoading(false);
                    return of(null); // Return an observable that emits null
                }
                const headers = {};
                if (socketId) {
                    Object.assign(headers,{'x-socket-id':socketId});
                    //headers['x-socket-id'] = socketId;
                }
                return this.http.post<AIRequestResponse>(requestURI, requestBody, { headers }).pipe(
                    takeUntil(this.cancelRequestSubject),
                    map(res => {
                      if (res) {
                        console.log(`AIChatRequest Result:`,res);
                        if (res.error) {
                            // Server could not successfully complete the request => Show toast for now
                            this.showError(res.error);
                            return;
                        }
    
                        // Get currently open sheet and table
                        const currentSheet:SheetReference = this.activeSheetObservable.getActive();
                        const elementsToLoad = ["chats"];
    
                        if (res.target.sheetChanges) elementsToLoad.push('sheet');
                        if (res.target.isNewSheet) elementsToLoad.push('sheets');
                        if (res.target.isNewTable) {
                            this.propagateAIEvent('containers');
                        }
    
                        // TODO: Implement Data Austerity Strategies, load only what's needed
                        // For now, we just reload everything

                        if (!this.visibilityObservable.isActive()) this.soundService.playAIDone();
                        
                        if (this.is(currentSheet,res.target)) {
                            // User is already on the target sheet => Reload chat and/or sheet
                            this.propagateAIEvent('load',res.target,elementsToLoad);
                            return;
                        } else {
                            // User is not on the target sheet
                            if (this.is(currentSheet,res.source)) {
                                // User is (still) on source sheet => Load target
                                this.propagateAIEvent('load',res.target,elementsToLoad);
                            } else {
                                // User is on another sheet => Show success message alerting the user the result
                                this.showSuccess(res.target);
    
                                // Also check for peripheral changes, i.e. changes that occured to sheets that aren't source or target
                                if (res.peripheralChanges) {
                                    for (const sheet of res.peripheralChanges) {
                                        if (this.is(currentSheet,sheet)) {
                                            // User is on a peripherally edited sheet => Reload chat and/or sheet
                                            this.propagateAIEvent('load',sheet);
                                        }
                                    }
                                    
                                }
                            }
                        }
                        return res.target;
                      }
                      return res;
                    }),
                    catchError(error => {
                      console.error('Error during HTTP request:', error);
                      return throwError(error);
                    }),
                    finalize(() => {
                      this.stateObservable.setLoading(false);
                    })
                  );
            })
        );
    }

    cancelRequest() {
        this.cancelRequestSubject.next();
        this.removeLastUserEntryFromConversation();
        this.stateObservable.setLoading(false);
    }

}