import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';

import { IUser, CognitoService } from '../../core/service/cognito.service';
import { Config } from '../../core/config/config'
import { UserService } from 'src/app/core/service/user.service';
import { ToastService } from 'src/app/toast/toast.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['sign-up.component.scss'],
})
export class SignUpComponent {

  loading: boolean;
  isConfirm: boolean;
  user: IUser;
  isSignUpContinue: any = {
    first: true,
    second: false,
    third: false,
    forth: false,
  };

  userForm: FormGroup;
  errorMsg: string = "";
  errorHide: boolean = false;

  teams = Config.signup_team;
  roles = Config.signup_role;
  passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastService: ToastService

  ) {
    this.loading = false;
    this.isConfirm = false;
    this.user = {} as IUser;
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  public confirmSignUp(stage:string): void {
    this.loading = true;
    this.cognitoService.confirmSignUp(this.user)
      .then(() => {
        let payload = this.user as IUser;
        payload['status'] = 'active';
        this.userService.createUser(payload)
        .subscribe();
        this.loading = false;
        this.setStageFalse();
        this.isSignUpContinue[stage] = true;
      }).catch((err) => {
        this.toastService.showError('Error', err);
        this.loading = false;
      });
  }

  async signUpContinue(stage: string) {
    if(stage == 'second'){
      this.loading = true;
      this.user.email = this.userForm.value.email;
      let payload = {
        email: this.userForm.value.email,
        status: 'inactive'
      }
      this.userService.createUser(payload)
      .subscribe({
        next: data => { 
          this.loading = false;
          this.setStageFalse();
          this.isSignUpContinue[stage] = true;
        },
        error: error => { 
          this.loading = false;
          this.toastService.showError('Error', error.error.message)
         },
        // complete: () => { console.log('Complete'); }
      });
    }
    if(stage == 'third'){
      this.user.firstName = this.user.name.split(' ')[0];
      this.user.lastName = this.user.name.split(' ')[1]?this.user.name.split(' ')[1]:"";
      this.cognitoService.signUp(this.user)
        .then((res) => {
          this.user.sub = res.userSub;
          this.loading = false;
          this.isConfirm = true;
        }).catch((err) => {
          if(err.code == 'UsernameExistsException'){
            return this.handleUnconfirmedUser()
          }
          console.log('err', err.message)
          this.errorMsg = err.message;
          this.toastService.showError('Error', err.message);
          this.loading = false;
        });
    }
    if(stage == 'forth'){
      this.setStageFalse();
      this.isSignUpContinue[stage] = true;
    }
    if(stage == 'login'){
      this.userService.createUser(this.user).subscribe();
      this.signIn()
    }

  }

  handleUnconfirmedUser(){
    this.cognitoService.resendCode(this.user.email)
    .then((res:any) => {
      this.loading = false;
      this.isConfirm = true;
      console.log('res', res)
    })
  }

  async signIn() {
    this.loading = true;
    this.cognitoService.signIn(this.user)
      .then((res:any) => {
        this.cognitoService.getUser()
        .then(async (user: any) => {
          this.user = user.attributes;
          window.sessionStorage.setItem('user', JSON.stringify(this.user));
          // await this.cognitoService.updateUser(this.user)
          this.router.navigate(['/']);
        });
      }).catch((err) => {
        console.log('err', err);
        this.toastService.showError('Error',err.message)
        this.loading = false;
      });
  }


  signUpwithSSO() {
    Auth.federatedSignIn(); // Sign in with SSO
  }

  signUpwithGoogle() {
    Auth.federatedSignIn();
  }

  saveTeam(data:any){
    this.user.team = data.name;
  }

  saveRole(data:any){
    this.user.role = data.name;
  }

  setStageFalse() {
    return this.isSignUpContinue = {
      first: false,
      second: false,
      third: false,
      forth: false,
    }
  }

  passwordValidator(){
    if (!this.passwordRegex.test(this.userForm.value.password)) {
      return true;
    }
    return false;
  }
}
