<div>
  <fieldset>
    <div class="row justify-content-center my-5">
      <div class="col-4">
        <div>
          <img src="../../../assets/img/vhi_logo.png" height="25" />
        </div>
        <div>
          <h3 class="">Reset Password</h3>
        </div>
        <div class="card">
          <div class="card-body" *ngIf="!isCodeSend">
            <div class="row">
              <div class="col mb-2">
                <label for="email" class="form-label">Email:</label>
                <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email"
                  class="form-control form-control-sm">
              </div>
            </div>
            <div class="row">
              <div class="col d-grid">
                <button type="button" (click)="resetPass()" class="btn btn-sm btn-success" [disabled]="loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    *ngIf="loading"></span>
                  Send Code
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col d-grid">
                <a href="" [routerLink]="['/signIn']">Back to sign-in</a>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="isCodeSend">
            <div class="row">
              <div class="col mb-2">
                <label for="code" class="form-label">Code:</label>
                <input type="text" id="code" name="code" #code="ngModel" [(ngModel)]="user.code" class="form-control form-control-sm">
              </div>
            </div>
            <div class="row">
              <div class="col mb-2">
                <label for="password" class="form-label">Password:</label>
                <div class="input-group input-group-sm">
                  <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password" #password="ngModel" [(ngModel)]="user.password" class="form-control form-control-sm">
                  <button type="button" class="btn btn-outline-secondary" (click)="user.showPassword = !user.showPassword">
                    <i class="bi" [ngClass]="{'bi-eye-fill': !user.showPassword, 'bi-eye-slash-fill': user.showPassword}"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col mb-2">
                <label for="confirmPassword" class="form-label">Confirm Password:</label>
                <div class="input-group input-group-sm">
                  <input [type]="user.showConfirmPass ? 'text' : 'confirmPassword'" id="confirmPassword" name="confirmPassword" #confirmPassword="ngModel" [(ngModel)]="user.confirmPassword" class="form-control form-control-sm">
                  <button type="button" class="btn btn-outline-secondary" (click)="user.showConfirmPass = !user.showConfirmPass">
                    <i class="bi" [ngClass]="{'bi-eye-fill': !user.showConfirmPass, 'bi-eye-slash-fill': user.showConfirmPass}"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col d-grid">
                <button type="button" (click)="forgotPasswordSubmit()" class="btn btn-sm btn-success" [disabled]="loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    *ngIf="loading"></span>
                  Submit
                </button>
              </div>
            </div>
            <div class="row">
                <a (click)="resetPass()">
                  Resend Code
                </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>