import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription, catchError, finalize, firstValueFrom } from 'rxjs';
import lottie from "lottie-web";
import Spreadsheet, { Options as SpreadsheetOptions, SheetData } from "x-data-spreadsheet";
import { defineElement } from '@lordicon/element';
defineElement(lottie.loadAnimation);
import { v4 as uuidv4 } from 'uuid';
import Split from 'split.js';

import { CognitoService } from 'src/app/core/service/cognito.service';
import { TablesService } from 'src/app/core/service/table.service';
import { ToastService } from 'src/app/toast/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateObservable, AIChatObservable, ActiveSheetObservable, SidebarItemCollapseObservable } from 'src/app/core/service/observable.service';
import { XSTable, XSSheet, TableListItem, TableList, ContainerSet, ContainerSetItem, GetTableDto, GetContainersDto, CreateNewTableDto, CreateSheetDto, AiLoadDataDto } from './spreadsheet.model';
import { AIEvent, UUID } from '../aichat/aichat.model';
import { ContainerService } from 'src/app/core/service/container.service';
import { FileDragNDropDirective } from 'src/app/core/directives/drag-n-drop.directives';
import { UserService } from 'src/app/core/service/user.service';
import { TooltipService } from 'src/app/tour/tour.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleService } from 'src/app/core/service/google.service';
import { FilesService } from 'src/app/core/service/files.service';
import { FileListItem, FileListItemEditable } from 'src/app/core/models/files.model';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { Config } from 'src/app/core/config/config';

export class SpreadsheetExt extends Spreadsheet {
    constructor(selector:string | HTMLElement, options: SpreadsheetOptions) {
      super(selector, options);
    }

    private getDimensions(sheet:SheetData): SheetData {
        if (!sheet.rows) sheet.rows = {};
        const maxRowKey:number = Math.max(...Object.keys(sheet.rows).map((key)=>{ return Number(key)||0}));
        Object.assign(sheet.rows,{"len":Math.max(maxRowKey + 100, 1000)});
        if (!sheet.cols) {
            sheet.cols = {len:0};
        }
        let maxColKey:number = 0;
        Object.values(sheet.rows).forEach(row=>{
            if (row.cells) {
                maxColKey = Math.max(maxColKey,...Object.keys(row.cells).map((key)=>{ return Number(key)||0}));
            }
        });
        sheet.cols.len = Math.max(maxColKey + 100, 100);
        //@ts-ignore
        console.log(`Sheet ${sheet.name} has ${sheet.rows.len!} rows and ${sheet.cols.len} cols.`)
        return sheet;
    }
  
    // Override the loadData method
    override loadData(data: Record<string, any>): this {
        if (Array.isArray(data)) {
            return super.loadData(data.map(this.getDimensions));
        } else {
            return super.loadData(this.getDimensions(data));
        }
    }
}


@Component({
    selector: 'app-spreadsheet',
    templateUrl: './spreadsheet.component.html',
    styleUrls: ['./spreadsheet.component.scss'],
})

export class SpreadSheetComponent implements OnInit {
    @ViewChild(FileDragNDropDirective) private fileDragDropDirective!: FileDragNDropDirective;
    @ViewChild('chatSlide') chatSlide: ElementRef | undefined;
    @ViewChild('tableSlide') tableSlide: ElementRef | undefined;
    private loadingSubscription!: Subscription;

    locked: boolean = false;

    spreadSheetData: any = "";
    spreadsheet!: Spreadsheet;
    tables: TableList = [];
    containers: ContainerSet = [];
    items = ['Item 1', 'Item 2', 'Item 3'];
    uploadFile: any;
    chats: any;
    suggestions: string[] = [];
    fileName: string = "";
    activeTable: UUID = "";
    activeSheet: XSSheet | undefined;
    activeTableSheets: any[] | undefined;
    activeContainer: UUID | undefined = "";
    inputChat: string = "";
    sheets: XSTable = [];
    showImportSheetModal = false;
    isSheetNameEdit: boolean = false;
    isXSEvtInitialized: boolean = false;
    editedSheetName: string = "";
    loading: boolean = false;
    uploadFileLoading: boolean = false;
    activeTableName: string = "";
    split: Split.Instance|undefined;
    downloadLoading: any = {
        xls: false,
        csv: false
    };
    isNewSheetCreated: boolean = false;
    folderLoading: boolean = false;
    draggingIndex: any = undefined;
    userData: any;
    draggingFromContainerId:string | undefined = '';
    googleAuthUrl: string = `${Config.backend_url}/google/auth`;
    user: any;
    uploads:FileListItemEditable[] = [];
    activeUpload: string = '';
    valid_MIME_types: string[] = [
        "text/csv",
        "application/pdf",
        "application/vnd.ms-excel", 
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.oasis.opendocument.spreadsheet", 
        "application/vnd.apple.numbers",
    ];
    
    valid_extensions: string[] = [
        ".numbers",
        ".pdf"
    ];
    
    valid_files = `${this.valid_MIME_types.join(",")},${this.valid_extensions.join(",")}`;
    signedUrlForPDF: string = '';
    dataForUploadFiles: any;
    showRawFileData : boolean = false;
    gOauthData: any;
    gOauthFlag = {
        analytics: false,
        ads: false
    }
    constructor(
        private tableService: TablesService,
        private cognitoService: CognitoService,
        private toastService: ToastService,
        private modalService: NgbModal,
        private stateObservable: StateObservable,
        private aiChatObservable: AIChatObservable,
        private activeSheetObservable: ActiveSheetObservable,
        private sidebarItemCollapseObservable: SidebarItemCollapseObservable,
        private containerService: ContainerService,
        private userService: UserService,
        private tooltipService: TooltipService,
        private route: ActivatedRoute,
        private googleSerivce: GoogleService,
        private router: Router,
        private filesService: FilesService,
        private airbyteService: AirByteService,
        private googleService: GoogleService
    ) {
    }

    ngOnInit(): void {
        this.spreadsheet = this.initSpreadsheet();        

        this.user = JSON.parse(window.sessionStorage.getItem('user') || '').sub || '';
        this.googleAuthUrl += '?sub=' + encodeURIComponent(this.user);
        //this.spreadsheet = new Spreadsheet("#x-spreadsheet-demo").loadData(this.spreadSheetData);
        this.spreadsheet.loadData(this.spreadSheetData || {});
        this.getContainers(false, false, false, true).then(()=> {
            if (this.tables.length) {
                this.getSpreadSheet(this.tables[0]);
            } else if (this.containers.length) {
                for (const cont of this.containers) {
                    if (cont.tables && cont.tables.length) {
                        this.getSpreadSheet(cont.tables[0]);
                        return;
                    }
                }
            }
        });
        // subscribe from headers to update container when delete is triggered
        this.tableService.getUpdateNotification().subscribe((data: any) => {
            this.activeContainer = data.containerId;
            this.getContainers(false, (this.activeContainer ? true:false));
        });
        this.subscribeToStateObservable();
        this.subscribeToAIChatObservable();
        //this.getFileUploads();
        this.getOAuthUser();
    }

    refreshIntegrations() {
        this.airbyteService.syncJobs().subscribe((res)=>{
            console.log("Jobs synced.");
            console.log(res);
        });
    }

    getWorkspaces(nCount:number=Infinity) {
        return this.tables
        .filter((table)=>{ 
                return table.type == 'workspace'; 
            }
        )
        .sort((a,b)=> { 
            return b.modifiedOn - a.modifiedOn; 
        })
        .slice(0,nCount);
    }
    getDataspaces(nCount:number=Infinity) {
        return this.tables
        .filter((table)=>{ 
                return table.type == 'dataspace'; 
            }
        )
        .sort((a,b)=> { 
            return b.modifiedOn - a.modifiedOn; 
        })
        .slice(0,nCount);
    }

    getFileUploads() {
        this.filesService.getUploadedFiles().subscribe((items: FileListItem[]) => {
            this.uploads = items;
        });
    }

    fileOpen(item:FileListItem) {

    }

    fileRename(item:FileListItemEditable) {

    }
    fileDelete(key:FileListItemEditable['key']) {

    }

    toggleSidebarItemCollapse(item?:"workspaces"|"dataspace"|"integrations"|"") {
        this.sidebarItemCollapseObservable.toggleSubject.next(item||"");
    }

    initSpreadsheet(): SpreadsheetExt {
        return new SpreadsheetExt("#x-spreadsheet-demo",{
            row: {
                len: 1000,
                height: 25,
              },
            col: {
            len: 100,
            width: 100,
            indexWidth: 60,
            minWidth: 60,
            },
            showToolbar: false,
            showBottomBar: false,
            style: {
                bgcolor: '#ffffff',
                align: 'left',
                valign: 'middle',
                textwrap: false,
                strike: false,
                underline: false,
                color: '#0a0a0a',
                font: {
                    // @ts-ignore
                    name: "proxima-nova",
                    size: 10,
                    bold: false,
                    italic: false
                }
            }
        });
    }

    ngAfterViewInit() {
        this.split = Split([this.chatSlide?.nativeElement,this.tableSlide?.nativeElement],{
            sizes: [50,50],
            minSize: 0
        });
    }

    subscribeToStateObservable() {
        this.loadingSubscription = this.stateObservable.loading$.subscribe(loading => {
            if (loading) {
                this.signedUrlForPDF = "";
                this.showRawFileData = false;
                this.lockTable();
            } else {
                this.unlockTable();
            }
        });
    }

    subscribeToAIChatObservable() {
        this.aiChatObservable.updates().subscribe(async (data: AIEvent) => {
            
            if (data.type == "containers") {
                await this.aiLoadContainers();
                return;
            }
            if (data.type == 'load') {
                await this.loadData(data.sheetId!,!data.data?.includes("chats"));
            }
            if (data.type == 'redirect') {
                await this.loadData(data.data);
                await this.aiLoadContainers();
            }
            if (data.type == 'table_stream') {
                if (!this.loading) {
                    this.spreadsheet.loadData(data.data);
                }
            }

        });
    }

    async loadData(sheetId:string,sheetOnly?:boolean,fields?:string[]): Promise<void> {
        //const data:AiLoadDataDto = await this.tableService.getSelectedFields(sheetId,fields) as AiLoadDataDto;
        const data = await this.tableService.getSheetData(sheetId,sheetOnly);
        if (data) {
            this.activeTable = data.tableId;
            this.activeTableName = data.tableName;
            this.activeContainer = data.containerId;
            this.activeSheet = data.sheet;
            if (!sheetOnly) {
                this.chats = data.chats || [];
                this.suggestions = data.suggestions || [];
                this.tableService.setTableChats(data.chats,data.suggestions);
            }
            this.sheets = data.sheets as XSTable;
            this.isNewSheetCreated = false;
            this.selectSheet(data.sheet);
            this.tableService.sendData(data);
        }
    }

    async switchSheetIfDifferent(sheet?:XSSheet) {
        if (!sheet) return;
        if (sheet.id != this.activeSheet?.id) {
            await this.loadData(sheet.id,true);
        } else {
            if (sheet.loaded) {
                sheet.showDropDown = !sheet.showDropDown;
            } else {
                sheet.loaded = true;
                sheet.showDropDown = false;
            }
            this.selectSheet(this.activeSheet);
        }
    }

    async aiLoadContainers() {
        this.containerService.getContainers()
        .subscribe((res:GetContainersDto) => {
            if(res && res.data){
                this.containers = res.data.dataWithContainerId || [];
                this.tables = res.data.dataWithoutContainerId ? res.data.dataWithoutContainerId.tables: [];
                this.tables.sort((a:any, b:any) => b.rank - a.rank);
                this.containers.map((_con:any) => _con.tables.sort((a:any, b:any) => b.rank - a.rank))
            } else {
                //setting blank if there are no records
                this.containers = [];
                this.tables = [];
                this.activeTable = '';
                this.activeTableName = '';
                this.activeContainer = '';
                this.chats = '';
            }
        })
    }

    loadSpreadSheetData(XSheets: XSTable) {
        // Update the data of the existing spreadsheet instance
        this.spreadsheet.loadData(XSheets);
    }

    updateSpreadSheetData() {
        this.spreadsheet
            .change((data: any) => {
            })
    }

    async loadTable(tableId: UUID,sheetId?: UUID) { console.log(`Loading table ${tableId}, sheet ${sheetId}..`);
        this.tableService.getTable(tableId)
            .subscribe((res: GetTableDto) => {
                if (res.data) {
                    this.chats = res.data.chats;
                    this.dataForUploadFiles = {};
                    this.showRawFileData = false;
                    this.signedUrlForPDF = '';
                    if (res.data.type == "dataspace") { console.log("is dataspace");
                        this.signedUrlForPDF = res.data.file?.signedUrl || '';
                        if (['xls','xlsx','numbers','csv'].includes(res.data.file?.extension||'')) {
                            this.tableService.getRawTableData(res.data.file!.fileName)
                            .subscribe((_res:GetTableDto) => {
                                this.dataForUploadFiles = _res;
                                this.showRawFileData = true;
                            })
                        }
                    }

                    this.sheets = res.data.XSheets as XSTable;
                    this.suggestions = res.data.suggestions || [];
                    this.tableService.setTableChats(res.data.chats,res.data.suggestions);

                    let sheet:XSSheet|undefined;

                    if (sheetId) {
                        sheet = this.sheets.find((s)=>{ return s.id == sheetId; });
                    } else {
                        let sheetIndex: number = ( this.isNewSheetCreated ? res.data.XSheets.length - 1 : res.data.activeSheet || 0);
                        sheet = this.sheets[sheetIndex];
                    }
                    
                    this.activeTable = tableId;
                    this.activeTableName = res.data.tableName;
                    this.activeContainer = res.data.containerId;
                    this.activeSheet = sheet;
                    this.selectSheet(sheet);

                    this.tableService.sendData(res.data);
                    this.isNewSheetCreated = false;
                    return true;
                }
                return false;
            });
    }

    getSpreadSheet(table: TableListItem) {
        //call api to get data
        table.loading = true;
        this.loadTable(table.tableId).then(()=>{
            table.loading = false;
        });
    }

    duplicateSheet(sheet: any) {
        this.tableService.duplicateSheet(this.activeTable, sheet)
            .subscribe((res: any) => {
                this.getContainers();
                this.toastService.showSuccess("Success", "Sheet is duplicated successfully.")
            })

    }

    duplicateTable(table:any){
        this.tableService.duplicateTable(table.tableId, table)
        .subscribe((res:any) => {
            this.getContainers();
            this.toastService.showSuccess("Success", "Table is duplicated successfully.");
        })
    }


    editItem(data: any) { }


    onItemDrop(event: any, isDrop?:boolean) {
        console.log("onItemDrop",event,isDrop);
        let _uploadFile:any;
        if(isDrop){
            _uploadFile = event[0];
        } else {
            _uploadFile = event.target.files[0];
        }
        this.uploadFileLoading = true;
        const formData: any = new FormData();
        formData.append('file', _uploadFile);
        this.fileName = _uploadFile.name;
        console.log(this.fileName,formData )
        this.tableService.uploadFile(formData, this.activeContainer)
            .subscribe(async (res: any) => {
                console.log("upload-callback!");
                this.activeTable = res.data.tableId;
                this.getContainers(true);
                // this.spreadsheet.loadData(res.data.xs);
                this.toastService.showSuccess('Success', "File Uploaded Successfully");
                this.fileName = "";
                this.fileDragDropDirective.resetDrop();
                this.uploadFileLoading = false;

            })
    }

    async initXSSpreadsheetEvents() {        
        if (!this.isXSEvtInitialized) {
            //@ts-ignore
            this.spreadsheet.on('cell-edited',(value:string|number,rowIndex:number,columnIndex:number,oldValue:string|number,state:string) => {
                if (this.locked) return;
                if (this.activeTable && this.activeSheet && this.activeSheet.id) {
                    if (state == 'finished') {
                        // Cell edit is finished and cell blured => Send to backend
                        const data = { value, oldValue };
                        this.tableService.updateTableCell(this.activeTable,this.activeSheet.id,rowIndex,columnIndex,data).subscribe();
                    } else {
                        // Cell input received, but cell is still focused => Also send to backend, but..
                        // TODO: Delay update until other action is taken?
                        const data = { value, oldValue };
                        this.tableService.updateTableCell(this.activeTable,this.activeSheet.id,rowIndex,columnIndex,data).subscribe();
                    }
                }
            });
            await this.spreadsheet.change((data: any) => {
                if (this.locked) return;
                if (this.activeTable && this.activeSheet && this.activeSheet.id) {
                    this.tableService.updateXSheetTable(this.activeTable, this.activeSheet.id, data)
                    .subscribe();
                }
            });
            this.isXSEvtInitialized = true;
        }
    }

    async updateManualEntries(changeData: any) {
        if (this.activeTable) {
            this.tableService.updateXSheetTable(this.activeTable, "", changeData)
                .subscribe();
        } else {
            let payload = {
                XSheets: changeData
            }
            this.tableService.createTables(payload).subscribe();
        }
    }


    lockTable() {
        this.locked = true;
        //@ts-ignore
        this.spreadsheet.sheet.data.settings.mode = "read";
    }
    unlockTable() {
        this.locked = false;
        //@ts-ignore
        this.spreadsheet.sheet.data.settings.mode = "edit";
    }

    uploadsheet(event: any, sheet: any) {
        const formData: any = new FormData();
        formData.append('file', event.target.files[0]);


        if (sheet && sheet.id) {
            this.tableService.uploadSheetFile(this.activeTable, sheet.id, formData)
                .subscribe((res: any) => {
                    this.spreadsheet.loadData(res.data.XSheets);
                    this.toastService.showSuccess('Success', "File Uploaded Successfully");
                    this.fileName = "";
                })
        } else {
            this.tableService.uploadNewSheetFile(this.activeTable, formData)
                .subscribe((res: any) => {
                    this.spreadsheet.loadData(res.data);
                    this.getContainers();
                    this.toastService.showSuccess('Success', "File Uploaded Successfully");
                    this.fileName = "";
                })
        }
    }

    editSheetName(sheet: any) {
        sheet.editedSheetName = sheet.name
        sheet.isSheetNameEdit = true;
        return
    }

    resetSheets() {
        this.sheets?.forEach((sheet: any) => {
            if (!this.activeSheet || this.activeSheet.id != sheet.id) {
                sheet.loaded = false;
                sheet.showDropDown = false;
            } else {
                sheet.loaded = true;
            }
        });
    }

    tableRename(table:any){
        table.tableName = table.editedName;
        this.tableService.updateTable(table.tableId,{ tableName : table.editedName})
        .subscribe((res:any) => {
          table.isNameEdit = false;
          this.tableService.setUpdateFromSpreadsheet(table);
          this.toastService.showSuccess('Success', "Table Name updated")
        })
    }

    async selectSheet(sheet: any) {
        if (!sheet) {
            // create dummy spreadsheet
            await this.spreadsheet.loadData({});
            return;
        }
        this.activeSheet = sheet;
        this.resetSheets();
        this.initXSSpreadsheetEvents();
        this.activeSheetObservable.setActiveSheet(sheet.id,this.activeTable);
        await this.spreadsheet.loadData(sheet);
    }

    stopEditingSheet(sheet: any) {
        sheet.isSheetNameEdit = false;
        this.tableService.updateSheets(this.activeTable, sheet.id, { name: sheet.editedSheetName })
            .subscribe((res: any) => {
                sheet.name = sheet.editedSheetName;
                sheet.isSheetNameEdit = false;
                this.editedSheetName = "";
            })
    }

    doubleClickEdit(table:any){
        table.isTableNameEdit = true;
        table.editedTableName = table.tableName;
    }

    stopEditingTable(table:any){
        this.tableService.updateTable(table.tableId, { tableName: table.editedTableName })
        .subscribe((res: any) => {
            table.isTableNameEdit = false;
            table.tableName = table.editedTableName;
            this.toastService.showSuccess('success', 'Dataset name updated.')
        })
    }

    doubleClickEditContainer(container:any){
        container.isContainerNameEdit = true;
        container.editedContainerName = container.containerName;
    }

    stopEditContainer(container:any) {
        this.containerService.updateContainer(container.containerId, { name: container.editedContainerName })
        .subscribe((res: any) => {
            container.isContainerNameEdit = false;
            container.containerName = container.editedContainerName;
            this.toastService.showSuccess('success', 'Folder name updated.')
        })
    }

    deleteItem(deleteId: any) {
        this.tableService.deleteSheet(this.activeTable, deleteId)
            .subscribe((res: any) => {
                this.getContainers(false, false, true);
                this.toastService.showSuccess("Success", "Sheet is deleted.")
            })
    }
    deleteTable(deleteId:string){
        this.tableService.deleteTable(deleteId)
        .subscribe((res:any) => {
            this.getContainers(false, true, false);
            this.toastService.showSuccess("Success", "Table is deleted.");
        })
    }
    deleteContainer(deleteId:string){
        this.containerService.deleteContainer(deleteId)
        .subscribe(res => {
            this.getContainers(false, true, false);
            this.toastService.showSuccess("Success", "Folder is deleted.")
        })
    }

    newDataset(containerId?:UUID) {
        this.loading = true;
        this.tableService.createTables({containerId: containerId ? containerId : ""}).subscribe((res: CreateNewTableDto) => {
            if(containerId){
                this.activeContainer = containerId;
            }
            this.toastService.showSuccess('Success', 'New Dataset created')
            this.loading = false;
            this.getContainers(true)
        });
    }

    createNewSheet() {
        this.tableService.createSheet(this.activeTable)
            .subscribe(async (res: CreateSheetDto) => {
                this.isNewSheetCreated = true;
                let tableData: TableListItem | undefined;
                if(this.activeContainer){
                    let containerData = this.containers.find((_cont:ContainerSetItem) => this.activeContainer == _cont.containerId);
                    if (containerData) {
                        tableData = containerData.tables.find((_table:TableListItem) => this.activeTable == _table.tableId);
                    }
                } else{
                    tableData = this.tables.find((_table:TableListItem) => this.activeTable == _table.tableId);
                }
                if (tableData) await this.getSpreadSheet(tableData);
                this.toastService.showSuccess("Success", "New Sheet Created.")
            })
    }

    // @praram1 - flag is for newdataset/new sheet
    // @param2 - flag is for dataset deleted/sheet deleted
    async getContainers(isNewDataSet?:boolean, isDeleted?:boolean, deleteSheet?:any, isRefresh?: boolean): Promise<void>{
        try {
            const res: GetContainersDto = await firstValueFrom(this.containerService.getContainers());
            if (res && res.data) {
                this.containers = res.data.dataWithContainerId || [];
                this.tables = res.data.dataWithoutContainerId ? res.data.dataWithoutContainerId.tables : [];
                this.tables.sort((a: any, b: any) => b.rank - a.rank);
                this.containers.map((_con: any) => _con.tables.sort((a: any, b: any) => b.rank - a.rank));
                await this.manageSpreadsheet({isNewDataSet, isDeleted, deleteSheet, isRefresh});
            } else {
                // Setting blank if there are no records
                this.containers = [];
                this.tables = [];
                this.activeTable = '';
                this.activeTableName = '';
                this.activeContainer = '';
                this.chats = '';
            }
        } catch(err) {
            // Setting blank if there are no records
            this.containers = [];
            this.tables = [];
            this.activeTable = '';
            this.activeTableName = '';
            this.activeContainer = '';
            this.chats = '';
        }
    }

    async manageSpreadsheet(options: { isNewDataSet?: boolean, isDeleted?: boolean, deleteSheet?: any , isRefresh?: boolean}) {
        const { isNewDataSet, isDeleted, deleteSheet, isRefresh } = options;

        if(isRefresh){
            let _id =  uuidv4();
            let sheet = { name: 'Sheet 1', rows : {}, cols: {}, freeze: "A2", id: _id, styles: [{}], merges:[]};
            this.sheets = [sheet];
            this.selectSheet(sheet);
            // await this.spreadsheet.loadData({});
            return;
        }
        const findActiveContainer = () => 
            this.containers.find(container => this.activeContainer === container.containerId);

        const updateActiveTable = (table: TableListItem) => {
            this.activeTable = table.tableId;
            this.activeTableName = table.tableName;
            this.activeContainer = table.containerId;
            this.tableService.sendData(table);
            this.chats = table.chats || [];
            this.getSpreadSheet(table);
        };

        if (isDeleted) {
            const activeContainer = findActiveContainer();
            if (activeContainer) {
                updateActiveTable(activeContainer.tables[0]);
                return;
            }
        }

        if (deleteSheet) {
            let targetTable = this.activeContainer ? 
                                findActiveContainer()?.tables.find(table => this.activeTable === table.tableId) : 
                                this.tables.find(table => this.activeTable === table.tableId);
            if (targetTable) {
                updateActiveTable(targetTable);
                return;
            }
        }

        if (this.activeContainer && !isNewDataSet) {
            this.tables.forEach(table => {
                if (table.tableId === this.activeTable) updateActiveTable(table);
            });
            return;
        }

        if(isNewDataSet === undefined){
            // for new container
            let target = this.containers[this.containers.length-1]
            if (target) {
                this.activeContainer = target.containerId;
                return;
            }
        }

        if (isNewDataSet) {
            const target = this.activeContainer ? findActiveContainer()?.tables.slice(-1)[0] : this.tables[0];
            if (target) {
                updateActiveTable(target);
                return;
            }
        }

        // Handle the default case where tables are not null and show the first table as selected
        const defaultTable = this.tables?.length ? this.tables[0] : this.containers[0].tables[0];
        if (defaultTable) updateActiveTable(defaultTable);
    }

    newContainer(){
        this.folderLoading = true;
        this.containerService.createContainer({})
        .subscribe(async (res:any)=>{
            this.folderLoading = false;
            await this.getContainers(undefined);
            this.toastService.showSuccess('success', 'New Folder is created successfully.')
        })
    }

    getUser(){
        this.userService.getUser(this.cognitoService.getLoggedInUser().email)
        .subscribe()
    }
    
    private _reorderItem(fromIndex: number, toIndex: number): void {
        const itemToBeReordered = this.tables.splice(fromIndex, 1)[0];
        this.tables.splice(toIndex, 0, itemToBeReordered);
        this.draggingIndex = toIndex;
        let tableOrder = this.tables.map((item:any) => item.tableId);
        var userData = {
            mapping: {"NA": tableOrder} 
        }
        this.userService.updateMapping(userData).subscribe();
    }

    private _reorderContainerTable(fromIndex: number, toIndex: number, container?:any): void {
        const itemToBeReordered = container.tables.splice(fromIndex, 1)[0];
        container.tables.splice(toIndex, 0, itemToBeReordered);
        const mapping: { [key: string]: string } = {};
        let containerId = container.containerId;
        let containerOrder = container.tables.map((item:any) => item.tableId);
        mapping[containerId] = containerOrder;
        var userData = {
            mapping: mapping
        }
        this.userService.updateMapping(userData).subscribe();
        this.draggingIndex = toIndex;
    }

    findContainerById(containerId: string): any {
        return this.containers.find(container => container.containerId === containerId);
    }

    toggleItemBetweenEntities(fromIndex: number, toIndex: number, fromContainerId?: string, toContainerId?: string): void {
        // Example logic for moving an item from a table to a container
        let item;
        if (!fromContainerId) {
          // Moving from main table list to a container
          item = this.tables.splice(fromIndex, 1)[0];
          let containerId:any;
          const toContainer = this.findContainerById(toContainerId || '');
          if (!toContainer) return;

          if(item){
              toContainer.tables.splice(toIndex, 0, item);
              containerId = toContainer.containerId;
          }

          const mapping: { [key: string]: string } = {};
          let containerOrder = toContainer.tables.map((item:any) => item.tableId);
          mapping[containerId] = containerOrder;
          var userData = {
              mapping: mapping
          }

          this.userService.updateMapping(userData).subscribe();
          this.draggingIndex = toIndex;
        } else if (!toContainerId) {
          // Moving from a container to the table list
          const fromContainer = this.findContainerById(fromContainerId);
          item = fromContainer.tables.splice(fromIndex, 1)[0];
          this.tables.splice(toIndex, 0, item);
          
        } else {
          // Moving between containers
          const fromContainer = this.findContainerById(fromContainerId);
          const toContainer = this.findContainerById(toContainerId);
          item = fromContainer.tables.splice(fromIndex, 1)[0];
          toContainer.tables.splice(toIndex, 0, item);
        }
      
        // Update userData and call updateUser as needed
        // This part will depend on your specific application logic
      }

    onDragEnter(toIndex: number, toContainerId?: string): void {
        if (this.draggingIndex !== undefined) {
            if (this.draggingFromContainerId !== toContainerId) {
                // Handle cross-entity drag-and-drop
                this.toggleItemBetweenEntities(this.draggingIndex, toIndex, this.draggingFromContainerId, toContainerId);
              } else {
                // Existing logic for within-entity reordering
                if (this.draggingIndex !== toIndex) {
                  if (toContainerId) {
                    const container = this.findContainerById(toContainerId);
                    this._reorderContainerTable(this.draggingIndex, toIndex, container);
                  } else {
                    this._reorderItem(this.draggingIndex, toIndex);
                  }
                }
              }
        }
      }

    onDragStart(fromIndex: number, fromContainerId?: string): void {
        console.log("Drag Start");
        this.draggingIndex = fromIndex;
        this.draggingFromContainerId = fromContainerId;
      }
  
    onDragEnd(): void {
        console.log("Drag End");
      this.draggingIndex = undefined;
    }

    toggleActiveContainer(activeCont:any){
        if (this.activeContainer == activeCont) {
            this.activeContainer = undefined;
        } else {
            this.activeContainer = activeCont;
        }
    }

    toggleAccordion(container:any){
        container.toogleChevron = !container.toogleChevron;
    }

    resetTable() {
        this.spreadsheet.loadData({});
    }

    async downloadXls() {
        if(this.activeTable){
            this.downloadLoading.xls = true;
            this.tableService.downloadXls(this.activeTable)
                .subscribe((blob: any) => {
                    this.tableService.triggerDownload(blob, `${this.activeTableName}.xls`);
                    this.downloadLoading.xls = false;
                    return;
                });
        }
    }

    async downloadCsv(){
        if (this.activeTable && this.activeSheet && this.activeSheet.id) {
            this.downloadLoading.csv = true;
            this.tableService.downloadCsv(this.activeTable, this.activeSheet.id)
                .subscribe((blob: any) => {
                    this.tableService.triggerDownload(blob, `${this.activeTableName}.csv`);
                    this.downloadLoading.csv = false;
                    return;
                });
        }
    }

    deleteId:string = '';
    confirm(content: any, id: any) {
        this.deleteId = id;
        this.modalService.open(content, { centered: true });
    }

    openGear(content: any, id: any) {
        this.modalService.open(content, { centered: true });
    }

    openModal(content:any){
        this.modalService.open(content, { centered: true });
    }

    startEditing(sheet: any) {
        sheet.editable = true;
        sheet.editedName = sheet.name;
    }

    openImportSheetModal() {
        this.showImportSheetModal = true;
    }

    closeImportSheetModal() {
        this.showImportSheetModal = false;
    }

    deleteSheetRequest() {
        
    }

    currentTooltipOrder: number = 0;
    showTooltipFlag: boolean = false;
    tooltips = [
        { content: 'First Tooltip', order: 1 },
        { content: 'Second Tooltip', order: 2 },
        { content: 'Third Tooltip', order: 3 }
        // Add more tooltips as needed
    ];

    showTooltip() {
        this.showTooltipFlag = true;
    }

    hideTooltip() {
        this.showTooltipFlag = false;
    }
    closeTooltip() {
        this.currentTooltipOrder = null || 0;
    }

    nextTooltip() {
        if (this.currentTooltipOrder < this.tooltips.length - 1) {
            this.currentTooltipOrder++;
        }
    }

    previousTooltip() {
        if (this.currentTooltipOrder > 0) {
            this.currentTooltipOrder--;
        }
    }

    getOAuthUser(){
        this.googleService.getOAuthUser().subscribe({
            next: (res:any) => {
                console.log('res', res)
                this.gOauthData = res.data;
                this.gOauthData.filter((_gAuth : any) => {
                    if(_gAuth.type === 'analytics'){
                        this.gOauthFlag.analytics = true;
                    } 
                    if(_gAuth.type === 'ads'){
                        this.gOauthFlag.ads = true;
                    }
                })
            }
        })
    }

    alert(text:any) {
        window.alert(text);
    }

}
