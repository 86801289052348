import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private http: HttpClient
    ) { }

    createUser(data: any) {
        return this.http.post(Config.backend_url + '/users/register', data);
    }

    loginUser(){
        return this.http.post(Config.backend_url + '/users/login', {});
    }

    updateUser(data:any){
        return this.http.patch(Config.backend_url+`/users`, data);
    }

    updateMapping(data:any){
        return this.http.patch(Config.backend_url+`/users/mapping`, data);
    }

    getUser(email:string){
        return this.http.get(Config.backend_url+`/users/${email}`);
    }

    getProfile() {
        return this.http.get(Config.backend_url+`/profile`);
    }

    googleIntegration(){
        return this.http.post(Config.backend_url + '/users/integration', {});
    }
}