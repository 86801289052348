import { Component, OnInit } from '@angular/core';
import lottie from "lottie-web";
import { defineElement } from '@lordicon/element';
import { StripeService } from '../stripe.services';
defineElement(lottie.loadAnimation);

@Component({
    selector: 'app-success-stripe',
    templateUrl: './stripe-success.component.html',


})
export class StripeSuccessComponent implements OnInit{
    constructor(
        private readonly stripeService: StripeService
    ) { 
    }

    ngOnInit(): void {
        // this.stripeService.directRedirection('month', 't4')
    }

}