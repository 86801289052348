import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AIChatMessage } from './aichat.model';
import { AIChatService } from 'src/app/core/service/aichat.service';
import { SpreadSheetComponent } from 'src/app/pages/spreadsheet/spreadsheet.component';
import { CognitoService } from 'src/app/core/service/cognito.service';
import { Config } from 'src/app/core/config/config';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-aichatmessage',
  templateUrl: './aichatmessage.component.html',
  styleUrls: ['./aichatmessage.component.scss'],
  providers: [DatePipe]
})
export class AIChatMessageComponent {

    @Input() content!: AIChatMessage;
    @ViewChild('contentContainer') contentContainer: ElementRef | undefined;
    @ViewChild('infoButton') infoButton: ElementRef | undefined;


    constructor(
        private datePipe: DatePipe,
        private spreadsheetComponent: SpreadSheetComponent,
        private readonly cognitoService: CognitoService,
        private aiChatService: AIChatService
    ) {}

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        if(!this.contentContainer) return;
        try {
            this.contentContainer.nativeElement.scrollTop = this.contentContainer.nativeElement.scrollHeight;
        } catch(err) { } 
      }

    getTime(): string {
        if (this.content.timestamp) {
            return this.datePipe.transform(this.content.timestamp, 'short') || '';
        } else {
            return '';
        }
    }

    getUsername(): string {
        return this.content.isAI ? 'Talonic AI' : this.cognitoService.getLoggedInUserName();
    }

    async openSheet(sheetId:string) {
        await this.spreadsheetComponent.loadData(sheetId);
    }

    getUserInitial() {
        return this.cognitoService.getLoggedInUserName()[0].toUpperCase();
    }

}