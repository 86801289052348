import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
    selector: 'shopify-integration',
    templateUrl: './shopify.component.html'
})

export class ShopifyComponent implements OnInit {

    shopifyForm: FormGroup;

    loading: boolean = false;
    shopifyData: any;
    jobHistory:any;
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService,
        private toastService: ToastService
    ) {
        this.shopifyForm = new FormGroup({
            shopName: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            startDate: new FormControl('')
          });
    }


    ngOnInit(): void {
        this.getShopify();
    }

    createShopify(){
        if (this.shopifyForm.valid) {
            this.loading = true;
            this.airbyteService.createShopify(this.shopifyForm.value)
            .subscribe((res: any) => {
                console.log(res)
                this.loading = false;
            })
        }
    }

    getShopify(){
        this.airbyteService.getAirbyteData('shopify')
            .subscribe({
                next: (response: any) => {
                    const result = response.data.reduce((acc: any, item: any) => {
                        const existingItem = acc[item.shop];
                        if (!existingItem || new Date(item.modifiedOn) > new Date(existingItem.modifiedOn)) {
                            acc[item.shop] = item;
                        }
                        return acc;
                    }, {});

                    // Convert the result object back to an array
                    this.shopifyData = Object.values(result);
                }
            })
    }

    syncJob(analyticData:any){
        this.airbyteService.syncJobStatus(analyticData.job_sync.jobId)
        .subscribe((response:any) => {
            this.shopifyData.map((_ad:any) => {
                if(_ad.job_sync.jobId === response.data.jobId){
                    _ad.job_sync.lastUpdatedAt = response.data.lastUpdatedAt
                    _ad.job_sync.jobStatus = response.data.status;
                    this.toastService.showSuccess('success', `job status is : ${response.data.status}`)
                }
            })
            if(response.data.status === 'succeeded') {
                this.syncDatatoS3(analyticData.property_id);
            }
        })
    }

    syncDatatoS3(propertyId: string) {
        this.airbyteService.syncDataToS3(propertyId)
            .subscribe({
                next: (res) => {
                    this.toastService.showSuccess('success', 'Data is successfully synced to s3');
                }
            })
    }

    redirectToJobHistory(_shopifyData:any){
        this.router.navigate([`/job-history/shopify/${_shopifyData.shop}`]);
    }

}
