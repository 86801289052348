import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute} from '@angular/router';

import { IUser, CognitoService } from '../../core/service/cognito.service';
import { ToastService } from 'src/app/toast/toast.service';
import { UserService } from 'src/app/core/service/user.service';
import { Config } from '../../core/config/config'
import { StripeService } from 'src/app/stripe/stripe.services';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit{

  loading: boolean;
  user: IUser;
  accountParam: any;
  billParam: any;
  paymentRedirection:boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cognitoService: CognitoService,
    private toastService: ToastService,
    private readonly userService: UserService,
    private readonly stripeService:  StripeService

  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.accountParam = params['a'];
      this.billParam = params['bill'];
      if(this.accountParam){
        const accountId = parseInt(this.accountParam.split('t')[1], 10);
        const account = Config.USER_ACCOUNTS.find(account => account.id === accountId);
        // 4 & 5 are standard and proffessional account type tier
        if (account && (account.id === 4 || account.id === 5)) {
          return this.paymentRedirection = true;
        }
      }
      return this.paymentRedirection = false;
    });
  }

  public signIn(): void {
    this.loading = true;
    this.cognitoService.signIn(this.user)
      .then((res:any) => {
        this.cognitoService.getUser()
        .then(async (user: any) => {
          const userData = user.attributes;
          this.userService.loginUser().subscribe((_lRes:any) => {
            //const userData = user.attributes;
            Object.assign(userData,_lRes);
            window.sessionStorage.setItem('user', JSON.stringify(userData));
            this.user = userData;
            if(this.paymentRedirection){
              this.stripeService.directRedirection(this.billParam, this.accountParam);
            } else {
              this.router.navigate(['/']);
            }
          });
        });

      }).catch((err) => {
        console.log('err', err);
        if(err.code == 'UserNotConfirmedException'){
          this.toastService.showError('Error', `${err.message}. Please sign up again`)
          this.loading = false;
          return
        }
        this.toastService.showError('Error',err.message)
        this.loading = false;
      });
  }

}
