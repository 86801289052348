<!-- ads-form.component.html -->
<div class="container mt-4">
    <form #adsForm="ngForm" (ngSubmit)="submit()">
      
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email"
               [(ngModel)]="adsDetails.email" class="form-control"
               required email>
               <div *ngIf="adsForm.controls['email']?.errors?.['required'] && adsForm.controls['email']?.touched" class="text-danger">
                Email is required.
              </div>
              <div *ngIf="adsForm.controls['email']?.errors?.['email'] && adsForm.controls['email']?.touched" class="text-danger">
                Please enter a valid email address.
              </div>
      </div>
  
      <div class="form-group">
        <label for="developersToken">Developer's Token:</label>
        <input type="text" id="developersToken" name="developersToken"
               [(ngModel)]="adsDetails.developersToken" class="form-control"
               required>
      </div>
  
      <div class="form-group">
        <label for="clientId">Client ID:</label>
        <input type="text" id="clientId" name="clientId"
               [(ngModel)]="adsDetails.clientId" class="form-control"
               required>
      </div>
  
      <div class="form-group">
        <label for="clientSecret">Client Secret:</label>
        <input type="text" id="clientSecret" name="clientSecret"
               [(ngModel)]="adsDetails.clientSecret" class="form-control"
               required>
      </div>
  
      <button type="submit" class="btn btn-primary" [disabled]="!adsForm.valid">Submit</button>
    </form>
  </div>
  