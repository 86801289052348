import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aichatresponse',
  templateUrl: './aichatresponse.component.html',
  styleUrls: ['./aichatmessage.component.scss'],
  providers: [DatePipe]
})
export class AIChatResponseComponent {

    @Input() content!: string[];
    @Input() showLoading: boolean = false;
    @ViewChild('contentContainer') contentContainer: ElementRef | undefined;

    constructor(
        private datePipe: DatePipe
    ) {}

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    scrollToBottom() {
        if(!this.contentContainer) return;
        try {
            this.contentContainer.nativeElement.scrollTop = this.contentContainer.nativeElement.scrollHeight;
        } catch(err) { } 
      }

    getTime(): string {
        return this.datePipe.transform(Date.now(), 'short') || '';
    }

}