import { HttpClient, HttpHeaders, HttpParams, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, firstValueFrom, Observable } from 'rxjs';

interface HTTPOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HttPromises {
  constructor(private http: HttpClient) {}

  async get<T>(url: string, options?:HTTPOptions): Promise<T> {
    return this.handleRequest<T>(this.http.get<T>(url, options));
  }

  async post<T, B>(url: string, body: B, options?:HTTPOptions): Promise<T> {
    return this.handleRequest<T>(this.http.post<T>(url, body, options));
  }

  async put<T, B>(url: string, body: B, options?: HTTPOptions): Promise<T> {
    return this.handleRequest<T>(this.http.put<T>(url, body, options));
  }

  async patch<T, B>(url: string, body: B, options?: HTTPOptions): Promise<T> {
    return this.handleRequest<T>(this.http.patch<T>(url, body, options));
  }

  async delete<T>(url: string, options?: HTTPOptions): Promise<T> {
    return this.handleRequest<T>(this.http.delete<T>(url, options));
  }

  private async handleRequest<T>(observable: Observable<T>): Promise<T> {
    try {
      return await firstValueFrom(observable.pipe(catchError(this.handleError)));
    } catch (error) {
      console.log(`Error while handling httPromises request: ${error}`);
      throw error;
    }
  }

  private handleError(error: any) {
    // extend error handling?
    return throwError(() => new Error(error));
  }
}
