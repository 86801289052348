import { Component } from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { Subscription } from 'rxjs';
import lottie from "lottie-web";
import { defineElement } from '@lordicon/element';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

defineElement(lottie.loadAnimation);
import { CognitoService } from 'src/app/core/service/cognito.service';
import { TablesService } from 'src/app/core/service/table.service';
import { ToastService } from 'src/app/toast/toast.service';

import { SidebarToggleObservable } from 'src/app/core/service/observable.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  subscription: Subscription = new Subscription;
  dataset:any;
  activeTab = 'all'; // Default active tab
  hideSideBarChevron: boolean = false;
  constructor(
    public cognitoService: CognitoService,
    private router: Router,
    private tableService: TablesService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private sidebarToggleObservable: SidebarToggleObservable,

  ){ }
  ngOnInit(){

    this.subscription = this.tableService.getData().subscribe((data:any) => {
      this.dataset = data;
    });

    this.subscription = this.tableService.getUpdateFromSpreadsheet().subscribe((data:any) => {
      this.dataset = data;
    });
  }
  logout(){
    this.cognitoService.signOut()
    .then((res:any) => {
        window.sessionStorage.clear();
        this.router.navigate(['/auth/signIn']);
    })
  }
  
  editTableName(table:any){
    table.tableName = this.dataset.tableName;
    table.editedSheetName = table.tableName;
    table.isSheetNameEdit = true;
    return
  }
  
  duplicateTable(table:any){
      this.tableService.duplicateTable(this.dataset.tableId, table)
      .subscribe((res:any) => {
          this.toastService.showSuccess("Success", "Table is duplicated successfully.")
          this.tableService.setUpdateNotification(res)
      })

  }
  deleteId: any;
  confirm(content: any, id: any) {
    this.deleteId = id;
    this.modalService.open(content, { centered: true });
  }

  
  deleteItem(deleteId:any){
      this.tableService.deleteTable(this.deleteId)
      .subscribe((res:any) => {
        // setting blank
        this.dataset.tableName = "";
          this.tableService.setUpdateNotification(this.dataset)
          this.toastService.showSuccess("Success", "Table is deleted.");
      })
  }
  
  stopEditing(table:any) {
    table.tableName = table.editedSheetName;
    this.tableService.updateTable(table.tableId,{ tableName : table.editedSheetName})
    .subscribe((res:any) => {
      table.isSheetNameEdit = false;
      this.toastService.showSuccess('Success', "Table Name updated")
      this.tableService.setUpdateNotification(table)
    })
  }

  setActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  toggleSidebar() {
    this.sidebarToggleObservable.toggleSidebar();
  }

  getUserInitial() {
    return this.cognitoService.getLoggedInUserName()[0].toUpperCase();
  }

  openModal(content:any){
    this.modalService.open(content, { centered: true });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}