import { Component, OnInit, Input } from '@angular/core';
import { AIChatService } from 'src/app/core/service/aichat.service';
import { Config } from 'src/app/core/config/config';
import { AIImage } from './aichat.model';

@Component({
  selector: 'app-ai-image',
  templateUrl: './aiimage.component.html'
})
export class AIImageComponent implements OnInit {

    @Input() image!: any;

    src: any;

    constructor(private aiChatService: AIChatService) {}

    ngOnInit() {
        const fileName = `${Config.image_url}/${this.image.url}`;
        this.aiChatService.fetchImage(fileName).subscribe((blob: Blob) => {
            this.src = URL.createObjectURL(blob);
        });
    }
}
