import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SpreadSheetComponent } from '../spreadsheet/spreadsheet.component';
import { AIChatMessage } from './aichat.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aichatinfo',
  templateUrl: './aichatinfo.component.html',
  styleUrls: ['./aichatinfo.component.scss']
})
export class AIChatInfoComponent {

    @Input() message: AIChatMessage|undefined;
    @ViewChild('contentContainer') contentContainer: ElementRef | undefined;
    isVisible:boolean = false;

    constructor(
      private spreadsheetComponent: SpreadSheetComponent,
      private datePipe: DatePipe
    ){}

    toggleInfo() {
        this.isVisible = !this.isVisible;
    }

    async openSheet(sheetId:string) {
      await this.spreadsheetComponent.loadData(sheetId);
    }

    getTime(): string {
      if (this.message?.timestamp) {
          return this.datePipe.transform(this.message.timestamp, 'short') || '';
      } else {
          return '';
      }
  }

}
