<div class="vhi_item vhi-gpt-{{content.type}} vhi-gpt-{{content.detailType}}" [ngClass]="content.isAI ? 'vhi-ai' : 'vhi-user'">
    <div class="vhi-item-content" [ngSwitch]="content.type">
        <ng-container *ngSwitchCase="'user'">
            <!-- Message is user prompt -->
            <div class="vhi-item-user-initial">{{getUserInitial()}}</div>
            <div class="vhi-item-name">{{getUsername()}}</div>{{content.content}}
        </ng-container>
        <ng-container *ngSwitchCase="'error'">
            <!-- Message is an error message -->{{content.content}}
        </ng-container>
        <ng-container *ngSwitchCase="'result'">
            <!-- Message is request result -->
             <app-aichatinfo [message]="content"></app-aichatinfo>
            <ng-container [ngSwitch]="content.detailType">
                <div class="vhi-item-name">Talonic AI</div>
                <ng-container *ngSwitchCase="'feedback'">
                    <!-- Immediate feedback ("Here is your result") -->{{content.content}}
                </ng-container>
                <ng-container *ngSwitchCase="'analysis'">
                    <!-- Analysis ("According to the data, sneakers are the best kind of shoes") -->{{content.content}}
                </ng-container>
                <ng-container *ngSwitchCase="'plot'">
                    <!-- Plot (PNG Image) -->
                    <div>{{content.content}}</div>
                    <app-ai-image [image]="content.data"></app-ai-image>
                </ng-container>
                <ng-container *ngSwitchCase="'other'">
                    <!-- Other messages (Further analysis, additional AI outputs) -->{{content.content}}
                </ng-container>
                <ng-container *ngSwitchCase="'crosslink'">
                    <!-- Link to another sheet containing the full results ("👉 Here you go") -->
                    <div>{{content.content}}</div>
                    <span>👉 </span><a (click)="openSheet(content.data!.ref)" class="vhi-item-link">{{content.data!.title}}</a>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <!-- Unknown / not yet implemented message type -->{{content.content}}
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'meta'">
            <!-- Message is a list of sources, references, further suggestions -->
            <ng-container [ngSwitch]="content.detailType">
                <ng-container *ngSwitchCase="'references'">
                    <!-- List of sheets used within the request -->
                    <div>Sheets used during processing:</div>
                    <ul>
                        <li *ngFor="let reference of content.data;">
                            <span>🔗 </span><a (click)="openSheet(reference.sheetId)">{{reference!.sheetName!}} ({{reference!.tableName!}})</a>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngSwitchCase="'sources'">
                    <!-- List of websites used within the request -->
                    <div>Internet sources used during processing:</div>
                    <ul>
                        <li *ngFor="let source of content.data;">
                            <span>🔗 </span><a href="{{source.url}}">{{source.title}}</a>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngSwitchCase="'sources'">
                    <!-- List of suggestions for refinement purposes -->
                    <ul>
                        <li *ngFor="let source of content.data;">{{content.data}}</li>
                    </ul>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <!-- Unknown / not yet implemented message type -->{{content.content}}
        </ng-container>
    </div>

    <div class="vhi-item-timestamp">{{ getTime() }}</div>
</div>