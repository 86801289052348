import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ai-status',
  templateUrl: './aichatstatus.component.html'
})
export class AIChatStatusComponent implements OnChanges {

    @Input() status!: string;

    activeStatus: string = "";
    lastStatus: string = "";
    flashStatus:boolean = false;
    
    private timer: NodeJS.Timeout | undefined;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
      if (changes['status']) {
        this.changeStatus();
      }
    }

    private setNewStatus() {
      this.flashStatus = false;
      this.lastStatus = this.activeStatus;
      this.activeStatus = this.status;
    }

    changeStatus() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = undefined;
      }
      if (this.lastStatus) {
        this.flashStatus = true;
        this.timer = setTimeout(()=>{
          this.setNewStatus();
        },(this.status ? 300 : 1000));
      } else {
        this.setNewStatus();
      }
    }
}
