<ng-container>
    <div class="vhi_item vhi-gpt-response vhi-gpt-feedback vhi-ai">
        <div class="vhi-item-content">
            <div class="vhi-item-name">Talonic AI</div>
            <ng-container *ngFor="let chunk of content; let isLast = last">
                <div [class.current]="isLast">{{chunk}}</div>
            </ng-container>
        </div>
        <div class="vhi-item-timestamp">{{ getTime() }}</div>
    </div>
</ng-container>