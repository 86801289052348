import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';

@Component({
    selector: 'app-integration',
    templateUrl: './integration.component.html'
})

export class IntegrationComponent implements OnInit {

    googleOAuthUser: any;

    constructor(
        private airbyteService: AirByteService,
        private route: ActivatedRoute,
        private googleService: GoogleService,
        private router: Router
    ) {
        // this.googleOAuthUser = JSON.parse(this.route.snapshot.paramMap.get('user') || '');
        // console.log('googleOAuthUser', this.googleOAuthUser)

    }


    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            const OauthId = params['id'];
            console.log('OauthId', OauthId)
            this.updateOauth(OauthId).subscribe(() => {
                this.router.navigate(['/']);

            });
        });
    }

    updateOauth(id: string) {
        return this.googleService.updateOauth({ id: id });
    }

    refreshAll() {
        this.airbyteService.syncJobs().subscribe((res)=>{
            console.log("Jobs synced.");
            console.log(res);
        });
    }

}
