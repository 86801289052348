<div class="auth">

  <div class="auth-logo">
    <img src="../../../assets/img/vhi_logo.png" height="25" />
  </div>

  <div class="row">
    <fieldset>
      <div class="row justify-content-center my-5">
        <div class="col-4">
          <div>
            <h3 class="">Sign In</h3>
            <div class="col row">
              or&nbsp; <a href="" [routerLink]="['/signUp']">reach out to get started</a>
            </div>
          </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col mb-2">
                    <label for="email" class="form-label">Email:</label>
                    <input type="email" id="email" name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control form-control-sm">
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-2">
                    <label for="password" class="form-label">Password:</label>
                    <div class="input-group input-group-sm">
                      <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password" #password="ngModel" [(ngModel)]="user.password" class="form-control form-control-sm">
                      <button type="button" class="btn btn-outline-secondary" (click)="user.showPassword = !user.showPassword">
                        <i class="bi" [ngClass]="{'bi-eye-fill': !user.showPassword, 'bi-eye-slash-fill': user.showPassword}"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-grid">
                    <button type="button" (click)="signIn()" class="btn btn-sm btn-success branded-col" [disabled]="loading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                      Sign in
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col d-grid">
                    <a href="" [routerLink]="['/resetPassword']" class="unstyled-a">Forgot your password?</a>
                  </div>
                </div>
                <!--<div class="row">
                  <div class="col d-grid">
                    <a href="" [routerLink]="['/signUp']">New User</a>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
    </fieldset>

    <div class="sign-up-promo">
      <h3>New here?</h3>
      <p>Discover how AI data management and analytics can empower your work and productivity.</p>
      <button type="button"class="btn btn-sm btn-success branded-col">
        <a href="" [routerLink]="['/signUp']">Get started</a>
      </button>
    </div>
  </div>
</div>
  