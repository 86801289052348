<div class="chat-status" [ngSwitch]="activeStatus" [ngClass]="{'flash': flashStatus}">
    <div class="starting-request" *ngSwitchCase="'common_start'">
        <!-- Request Start -->
        <lord-icon src="../../../assets/img/icons/circle.json" trigger="loop"></lord-icon>
        Queuing your request
    </div>

    <div class="analyzing-intent" *ngSwitchCase="'common_intent'">
        <!-- Determining intent -->
        <lord-icon src="../../../assets/img/icons/circle.json" trigger="loop"></lord-icon>
        Analyzing your request
    </div>

    <div class="processing-sheets" *ngSwitchCase="'common_processing'">
        <!-- Processing unprocessed sheets -->
        <lord-icon src="../../../assets/img/icons/circle.json" trigger="loop"></lord-icon>
        Preparing your data
    </div>

    <div class="analyzing" *ngSwitchCase="'analyze_start'">
        <!-- Analyze Start -->
        <lord-icon src="../../../assets/img/icons/robot.json" trigger="loop"></lord-icon>
        Preparing to analyze data
    </div>

    <div class="visualizing-data" *ngSwitchCase="'analyze_plot'">
        <!-- Analyze - Create Plot -->
        <lord-icon src="../../../assets/img/icons/graph-chart.json" trigger="loop"></lord-icon>
        Visualizing data
    </div>

    <div class="analyzing-data" *ngSwitchCase="'analyze_text'">
        <!-- Analyze - Text -->
        <lord-icon src="../../../assets/img/icons/robot.json" trigger="loop"></lord-icon>
        Analysing data
    </div>

    <div class="bantering" *ngSwitchCase="'banter_start'">
        <!-- Banter -->
        <lord-icon src="../../../assets/img/icons/robot.json" trigger="loop"></lord-icon>
        1000 monkeys are typing
    </div>

    <div class="creating-table" *ngSwitchCase="'create_start'">
        <!-- Create Sheet - Start -->
        <lord-icon src="../../../assets/img/icons/table-outline.json" trigger="loop"></lord-icon>
        Creating a new sheet
    </div>

    <div class="creating-spreadsheet-outline" *ngSwitchCase="'create_outline'">
        <!-- Create Sheet - PreCreate, Preliminary sheet outline -->
        <lord-icon src="../../../assets/img/icons/table-outline.json" trigger="loop"></lord-icon>
        Building spreadsheet outline
    </div>

    <div class="creating-spreadsheet-strategy" *ngSwitchCase="'create_columns'">
        <!-- Create Sheet - Columns, Choosing which to fill -->
        <lord-icon src="../../../assets/img/icons/table-outline.json" trigger="loop"></lord-icon>
        Developing a strategy
    </div>

    <div class="building-spreadsheet" *ngSwitchCase="'create_prefill'">
        <!-- Create Sheet - Fill, LLM data -->
        <lord-icon src="../../../assets/img/icons/table.json" trigger="loop"></lord-icon>
        Building spreadsheet
    </div>

    <div class="searching-user-data" *ngSwitchCase="'create_fill'">
        <!-- Create Sheet - Black Box, Script execution -->
        <lord-icon src="../../../assets/img/icons/search.json" trigger="loop"></lord-icon>
        Searching your data
    </div>



    <div class="analyzing-sheet" *ngSwitchCase="'search-create_analyze'">
        <!-- Create Sheet - Post, Analysis -->
        <lord-icon src="../../../assets/img/icons/robot.json" trigger="loop"></lord-icon>
        Analysing spreadsheet
    </div>

    <div class="inserting-internet-data" *ngSwitchCase="'search-internet'">
        <!-- **NOT USED** -->
        <lord-icon src="../../../assets/img/icons/internet.json" trigger="loop"></lord-icon>
        Searching the internet
    </div>
    
</div>