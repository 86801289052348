import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { SpreadSheetComponent } from './spreadsheet/spreadsheet.component';
import { AIChat } from './aichat/aichat.component';
import { AIChatMessageComponent } from './aichat/aichatmessage.component';
import { AIChatStatusComponent } from './aichat/aichatstatus.component';
import { AIChatResponseComponent } from './aichat/aichatresponse.component';
import { AIChatAudioComponent } from './aichat/aichataudio.component';
import { AIChatInfoComponent } from './aichat/aichatinfo.component';
import { AIImageComponent } from './aichat/aiimage.component';
import { FileDragNDropDirective } from '../core/directives/drag-n-drop.directives';
import { OrderByPipe } from '../core/pipes/order-by.pipe';
import { TooltipComponent } from '../tour/tour.component';
import { GoogleAnalyticsComponent } from './integration/analytics/analytics.component';
import { AdsComponent } from './integration/ads/ads.component';
import { ShopifyComponent } from './integration/shopify/shopify.component';
import { WooComComponent } from './integration/woocom/woocom.component';
import { SpreadSheetSourceComponent } from './spreadsheetsource/spreadsheetsource.component';
import { HubspotComponent } from './integration/hubspot/hubspot.component';
import { JobHistoryComponent } from './integration/job-history/job-history.component';
import { ToastService } from '../toast/toast.service';
import { PartialsModule } from '../partials/partials.module';
import { DriveComponent } from './integration/drive/drive.component';

@NgModule({
  declarations: [
    PagesComponent,
    ProfileComponent,
    SpreadSheetComponent,
    AIChat,
    AIChatMessageComponent,
    AIChatResponseComponent,
    AIChatStatusComponent,
    AIChatAudioComponent,
    AIChatInfoComponent,
    AIImageComponent,
    FileDragNDropDirective,
    OrderByPipe,
    GoogleAnalyticsComponent,
    AdsComponent,
    ShopifyComponent,
    WooComComponent,
    SpreadSheetSourceComponent,
    HubspotComponent,
    SpreadSheetSourceComponent,
    JobHistoryComponent,
    DriveComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    RouterModule,
    PagesRoutingModule,
    PartialsModule
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class PagesModule { 
  constructor(){
  }
}
