import { Directive, HostListener, HostBinding, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[fileDragDrop]'
})

export class FileDragNDropDirective {
  @Input() resetDrop() {
    this.cDrop = false;
  }
  //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];

  @Input() allowedMimeTypes: string[] = [];
  @Input() allowedExtensions: string[] = [];
  @Output() private filesChangeEmitter : EventEmitter<File[]> = new EventEmitter();
  @Output() private filesInvalidEmitter : EventEmitter<File[]> = new EventEmitter();

  @HostBinding('class.dragover')      private cDragOver     = false;
  @HostBinding('class.dragidle')      private cDragIdle     = true;
  @HostBinding('class.drop')          private cDrop         = false;
  @HostBinding('class.drag-invalid')  private cDragInvalid  = false; 

  constructor() { }

  private isValid(file:DataTransferItem|File, isDrop:boolean=false) {
    if (this.allowedMimeTypes.includes(file.type)) return true;
    if (!isDrop) {
      return !file.type;
    }
    if (file instanceof File) {
      const ext = (file.name||"").split(".").pop() || '';
      return this.allowedExtensions.includes(ext);
    }
    const ext = (file.getAsFile()?.name||"").split(".").pop() || '';
    return this.allowedExtensions.includes(ext);
  }

  private resetDrag() {
    this.cDragOver = false;
    this.cDragIdle = true;
    this.cDragInvalid = false;
  }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent){
    evt.preventDefault();
    evt.stopPropagation();
    this.cDragOver = true;
    this.cDragIdle = false;
    this.cDrop = false;

    if (evt.dataTransfer && evt.dataTransfer.items) {
      this.cDragInvalid = !Array.from(evt.dataTransfer.items).some(item => this.isValid(item));
    }

  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.resetDrag();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent){
    evt.preventDefault();
    evt.stopPropagation();
    this.resetDrag();

    if (!evt.dataTransfer?.files) return;

    this.cDrop = true;

    let files = evt.dataTransfer?.files;
    let validFiles: File[] = [];
    let invalidFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (this.isValid(file,true)) {
        if (!validFiles.map(v=>v.name).includes(file.name)) {
          validFiles.push(file);
        }
      } else {
        if (!invalidFiles.map(v=>v.name).includes(file.name)) {
          invalidFiles.push(file);
        }
      }
    }
    if (validFiles.length > 0) {
      this.filesChangeEmitter.emit(validFiles);
    }
    if (invalidFiles.length > 0) {
      this.filesInvalidEmitter.emit(invalidFiles);
    }
  }
}