<div>
    <div class="container mt-3">
        <div class="row">
            <div class="card col-md-6">
                <div class="card-header">
                    Google Drive
                </div>
                <div class="card-body">
                    <form [formGroup]="driveForm" (ngSubmit)="createDrive()">
                        <h5 class="card-title">Add Details</h5>
                        <div class="card-flex">
                            <div>
                                <label for="folder_id">Folder Id:</label>
                                <input type="text" id="folder_id" class="form-control form-control-sm" placeholder="Folder Id" formControlName="folder_id" required="required" />
                            </div>
                            <div>
                                <label for="account_id">Google Account:</label>
                                <select id="account_id" required="required" formControlName="account_id" class="form-control form-control-sm">
                                    <option *ngFor="let account of accounts" [value]="account">{{account}}</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">Submit</button>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                            *ngIf="loading"></span>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row p-2">
                <div class="col-md-12" *ngIf="driveData">
                    <h4>Drive Data</h4>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col"></th> <!-- Added for the expand/collapse icon -->
                                <th scope="col">Folder Id</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">Last Sync Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let _drive of driveData">
                                <tr (click)="toggleRow(_drive.Id)">
                                    <td>
                                        <!-- Arrow icon that toggles direction -->
                                        <i class="bi" [ngClass]="{'bi-chevron-right': currentExpandedRow !== _drive.Id, 'bi-chevron-down': currentExpandedRow === _drive.Id}"></i>
                                    </td>
                                    <td>{{ _drive.Id }}</td>
                                    <td>{{ _drive.createdOn | date: 'dd-MM-yyyy, HH:mm:ss' }}</td>
                                    <td>{{ _drive.modifiedOn | date: 'dd-MM-yyyy, HH:mm:ss' }}</td>
                                    <td>{{ _drive.last_job_status }}</td>
                                    <td>
                                        <i class="bi bi-arrow-clockwise p-2" placement="bottom" ngbTooltip="Synchronize" (click)="createDrive(_drive); $event.stopPropagation();"></i>
                                    </td>
                                </tr>
                                <!-- Additional Row for Files -->
                                <tr *ngIf="currentExpandedRow === _drive.Id">
                                    <td colspan="6"> <!-- Updated colspan to 6 -->
                                        <div *ngFor="let file of _drive.files">
                                            {{ file }}
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        
    </div>
</div>