import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';

@Component({
    selector: 'ads-integration',
    templateUrl: './ads.component.html'
})

export class AdsComponent implements OnInit {

    adsDetails: any = {
        email: '',
        developersToken: '',
        clientId: '',
        clientSecret: ''
      };

    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService
    ) {}


    ngOnInit(): void {

    }

    submit(){
        this.googleService.verifyUser(this.adsDetails)
        .subscribe({
            next : (response: any) => {
                console.log('response', response.data)
            },
            error: (error:any) => {

            }
        })
    }
}
