import { environment } from '../../../environments/environment';

export const Config = {
    "cognito": {
        "userPoolId": 'eu-north-1_bWjzqzjkm',
        "userPoolWebClientId": '2noba89b9rbn2nl0qgnbnok0bq',
    },
    "localHost":  environment.backend_url,
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_bWjzqzjkm",
    "aws_user_pools_web_client_id": "2noba89b9rbn2nl0qgnbnok0bq",
    "aws_cognito_identity_pool_id": "eu-north-1:6541617a-eb15-47c2-80ce-e6b5f69e1962",
    "aws_mandatory_sign_in": "enable",
    "Subscription": [
        "Free", "Standard", "Premium", "Enterprise", "Admin"
    ],
    "backend_url": environment.backend_url,
    "websocket_url": environment.websocket_url,
    "image_url": environment.image_url,
    "signup_team": [
        {id: 1, name: "Marketing"}, {id:2, name: "HR & Legal"}, {id:3, name: "Product & Design"}, 
        {id: 4, name: "Creative Production"}, {id: 5, name: "Engineering"}, {id: 6, name: "Customer Service"},
        {id: 7, name: "Operations"}, {id: 8, name: "Finance"}, {id: 9, name: "IT & Support"},
        {id: 10, name: "Manufacturing"}, {id: 11, name: "Sales & Account Mgmt."},{id: 12, name: "Other / Personal"}
    ],
    "signup_role": [
        {id: 1, name: "Owner"}, {id:2, name: "C-Suite"}, {id:3, name: "Vice President"}, 
        {id: 4, name: "Director"}, {id: 5, name: "Team Leader"}, {id: 6, name: "Team Member"},
        {id: 7, name: "Freelancer"}, {id: 8, name: "Other"}
    ],
    'USER_ACCOUNTS': [
        { id: 1, name: 'Admin' }, 
        { id: 2, name: 'Talonic Staff' }, 
        { id: 3, name: 'Freemium' }, 
        { id: 4, name: 'Individual' }, 
        { id: 5, name: 'Business' }, 
        { id: 6, name: 'Premium' }, 
        { id: 7, name: 'Enterprise' }
    ],
    'ACCESS_LEVEL' : ['Owner', 'Admin', 'User'],
    'businessPlan_year': "https://buy.stripe.com/fZe8zceBP9fG46s7sC",
    'businessPlan_month': "https://buy.stripe.com/3cseXA51f1Ne6eA4gr",
    'individualPlan_year': "https://buy.stripe.com/9AQbLo65j0Ja6eA7sE",
    'individualPlan_month': "https://buy.stripe.com/4gwaHkgJX2RibyUdR3"

}