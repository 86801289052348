import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../config/config';
import { TablesService } from './table.service';

@Injectable({
    providedIn: 'root',
})
export class ContainerService {
    constructor(
        private http: HttpClient,
        private tableService: TablesService
    ) { }

    createContainer(data: any) {
        return this.http.post(Config.backend_url + '/containers', data)
    }

    updateContainer(id: string, data: any) {
        return this.http.patch(Config.backend_url + `/containers/${id}`, data)
    }

    getContainers(){
        return this.http.get(Config.backend_url + `/containers/user`);
    }

    getContainerById(id:string){
        return this.http.get(Config.backend_url + `/containers/${id}`)
    }

    deleteContainer(id:string){
        return this.http.delete(Config.backend_url + `/containers/${id}`)
    }

}