import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
    selector: 'drive-integration',
    templateUrl: './drive.component.html'
})

export class DriveComponent implements OnInit {
    currentExpandedRow: string | null = null;
    driveForm!: FormGroup;
    driveData: any;
    accounts:{ mail: string; id: string; }[] = [];
    loading = false;
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService,
        private formBuilder: FormBuilder,
        private toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        this.driveForm = this.formBuilder.group({
            folder_id: ['', Validators.required],
            account_id: ['', Validators.required],
        });
        this.getDriveData();
        this.getAccounts();
    }

    createDrive(id?: string) {
        this.loading = true;
        this.googleService.createDrive(this.driveForm.value.account_id, this.driveForm.value.folder_id)
            .subscribe((res: any) => {
                console.log(res)
                this.loading = false;
            })
    }

    getDriveData() {
        this.googleService.getDrive().subscribe((res: any) => {
            this.driveData = res.data
        })
    }

    getAccounts() {
        this.googleService.getDriveAccounts().subscribe((res: any) => {
            this.accounts = res;
        })
    }

    toggleRow(driveId: string) {
        if (this.currentExpandedRow === driveId) {
            this.currentExpandedRow = null;
        } else {
            this.currentExpandedRow = driveId;
        }
    }

}
