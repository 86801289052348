<div id="vhi_chat" [ngClass]="visible ? 'visible' : 'hidden'" >
    <div class="toolbar">
        <!--<div class="clear-chat" (click)="resetTable()">
            <lord-icon src="../../../assets/img/refresh.json" trigger="hover"></lord-icon>Restart Chat
        </div>-->
        <!--<div class="hide-chat" (click)="toggleChat()">
            <lord-icon src="../../../assets/img/icons/hide-to-right.json" trigger="hover"></lord-icon>{{ visible == true ? 'Hide Chat' : 'Show Chat' }}
        </div>-->
    </div>
    <div class="vhi_element_wrapper" [ngClass]="{ 'isEmpty': !conversation.length }">
        <div #contentContainer class="chat_items">

            <div class="vhi_empty_chat">
                <img src="../../../assets/img/icons/talonic_icon.png"/>
                <div class="prompt">What do you want to work on?</div>
            </div>

            <ng-container *ngFor="let _item of conversation;">
                <app-aichatmessage [content]="_item"></app-aichatmessage>
            </ng-container>
            <app-aichatresponse [content]="response" *ngIf="response.length" [showLoading]="showLoading"></app-aichatresponse>
            <ng-container *ngIf="loading&&!response.length">
                <div class="vhi_item vhi-gpt-loading in-progress vhi-ai">
                    <div class="vhi-item-content">
                        <div class="dot-pulse"></div>
                    </div>
                </div>
            </ng-container>
            <app-ai-status [status]="status"></app-ai-status>
        </div>
        <div class="vhi_element input">
            <ng-container *ngIf="suggestions.length">
                <div id="vhi-gpt-suggestions">
                    <ng-container *ngFor="let suggestion of suggestions;">
                        <div (click)="useSuggestion(suggestion)">{{suggestion}}</div>
                    </ng-container>
                </div>
            </ng-container>
            <input id="vhi-gpt-input" placeholder="Message Talonic ..." name="vhi_prompt" type="text"
            [(ngModel)]="inputChat" autocomplete="off" (keyup)="processInputChat($event)" [disabled]="loading" />                     
            <div id="vhi-gpt-send" class="vhi_element send" (click)="processInputChat()">
                <lord-icon src="../../../assets/img/send.json" trigger="hover"></lord-icon>
            </div>
            <app-aichataudio></app-aichataudio>
            <div id="stop-generating" (click)="cancelOngoingRequest()" *ngIf="loading">Stop</div>
        </div>
    </div>
</div>