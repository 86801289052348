<div class="auth">

    <div class="auth-logo">
      <img src="../../../assets/img/vhi_logo.png" height="25" />
    </div>

    <div class="full-vp-message">
        <div class="row">
              <lord-icon src="../../../assets/img/icons/slip.json" trigger="loop"></lord-icon>
            <h1>Outsch. Something went wrong.</h1>
        </div>
        <div>
            <p>Your subscription did not come through. You won't get charged.</p>
            <p>Please <a href="https://talonic.ai/pricing">try again</a> or reach out to our <a href="mailto: info@talonic.ai">support</a>. We are happy to help.</p>            
        </div>
    </div>
</div>