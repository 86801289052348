import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core'
import { ConsoleLogger as LoggerClass } from '@aws-amplify/core';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import { Config } from '../config/config'

export interface IUser {
  email: string;
  password: string;
  confirmPassword:string;
  showPassword: boolean;
  showConfirmPass: boolean;
  code: any;
  name: string;
  firstName: string;
  lastName: string;
  team: string;
  role:string;
  status:string;
  sub?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {

  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    Amplify.configure({
      Auth: {
        region: Config.aws_cognito_region,
        userPoolId: Config.aws_user_pools_id,
        userPoolWebClientId: Config.aws_user_pools_web_client_id,
      }
    });

    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  getLoggedInUser(){
    return JSON.parse(window.sessionStorage.getItem('user') || '');
  }

  getLoggedInUserName(): string {
    const user = this.getLoggedInUser();
    return user.firstName || user.name || user.email;
  }

  public signUp(user: IUser): Promise<ISignUpResult> {
    return Auth.signUp({
      username: user.email,
      password: user.password,
    });
  }

  public confirmSignUp(user: IUser): Promise<any> {
    return Auth.confirmSignUp(user.email, user.code);
  }

  public async signIn(user: IUser): Promise<any> {
    const loggedInDetails = await Auth.signIn(user.email, user.password);
    window.sessionStorage.setItem('accessToken', loggedInDetails.signInUserSession.accessToken.jwtToken);
    return this.authenticationSubject.next(true);
  }

  public async signOut(): Promise<any> {
    await Auth.signOut();
    return this.authenticationSubject.next(false);
  }

  public async forgotPassword(user: IUser): Promise<any> {
    await Auth.forgotPassword(user.email);
    return this.authenticationSubject.next(true);
  }

  public async UpdateUserAttributes(data:any): Promise<any> {
    await Auth.updateUserAttributes(data.Username, data.UserAttributes);
  }

  public async forgotPasswordSubmit(user: IUser): Promise<any> {
    await Auth.forgotPasswordSubmit(user.email, user.code, user.password);
    return this.authenticationSubject.next(true);
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
      .then((user: any) => {
        if (user) {
          return true;
        } else {
          return false;
        }
      }).catch(() => {
        return false;
      });
    }
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public async updateUser(user: IUser): Promise<any> {
    return Auth.currentUserPoolUser()
    .then((cognitoUser: any) => {
      return Auth.updateUserAttributes(cognitoUser, user);
    });
  }

  public resendCode(email:string){
    return Auth.resendSignUp(email)
  }

}