import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StateObservable } from '../core/service/observable.service';
import { SidebarToggleObservable, SidebarItemCollapseObservable } from 'src/app/core/service/observable.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  private loadingSubscription!: Subscription;
  private sidebarToggledSubscription!: Subscription;
  private sidebarItemSubscription!: Subscription;

  isLoading: boolean = false;
  sidebarToggled: boolean = false;
  activeSidebarItem: string = "";

  constructor(
    private router: Router,
    private stateObservable: StateObservable,
    private sidebarToggleObservable: SidebarToggleObservable,
    private sidebarItemCollapseObservable: SidebarItemCollapseObservable
  ){}

  ngOnInit() {
    this.subscribeToStateObservable();
    this.subscribeToSidebarToggleObservable();
    this.subscribeToSidebarItemCollapseObservable();
  }

  subscribeToStateObservable() {
    this.loadingSubscription = this.stateObservable.loading$.subscribe(loading => {
        if (loading) {
            this.setIsLoading();
        } else {
            this.unsetIsLoading();
        }
    });
  }

  subscribeToSidebarToggleObservable() {
    this.sidebarToggledSubscription = this.sidebarToggleObservable.toggled$.subscribe(toggled => {
        if (toggled) {
            this.setSidebarToggled();
        } else {
            this.setSidebarHidden();
        }
    });
  }

  subscribeToSidebarItemCollapseObservable() {
    this.sidebarItemSubscription = this.sidebarItemCollapseObservable.activeItem$.subscribe(item => {
      this.activeSidebarItem = item;
    });
  }

  setIsLoading() {
    this.isLoading = true;
  }
  unsetIsLoading() {
    this.isLoading = false;
  }
  setSidebarToggled() {
    this.sidebarToggled = true;
  }
  setSidebarHidden() {
    this.sidebarToggled = false;
  }
  toggleCollapseSidebarItem() {

  }
}
