import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { IUser, CognitoService } from '../../core/service/cognito.service';
import { ToastService } from 'src/app/toast/toast.service';


@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss'],
})
export class ResetPasswordComponent {

  loading: boolean;
  user: IUser;
  isCodeSend = false;

  constructor(
    private router: Router,
    private cognitoService: CognitoService,
    private toastService: ToastService


  ) {
    this.loading = false;
    this.user = {} as IUser;
  }

  public resetPass(): void {
    this.loading = true;
    this.cognitoService.forgotPassword(this.user)
      .then((res:any) => {
        this.isCodeSend = true;
        this.loading = false;
        this.toastService.showSuccess('Success', `Reset password code send to email ID ${this.user.email}`)
      }).catch((err) => {
        this.toastService.showError('Error',err.message)
        this.loading = false;
      });
  }

  forgotPasswordSubmit():void {
    this.loading = true;
    this.cognitoService.forgotPasswordSubmit(this.user)
    .then((res:any) => {
      this.loading = false;
      this.toastService.showSuccess('Success', 'Password updated')
      this.router.navigate(['./auth/signIn']);
    }).catch((err) => {
      this.toastService.showError('Error',err.message)
      this.loading = false;
    });
  }

}
