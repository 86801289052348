import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
  HttpEvent
} from '@angular/common/http';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { RefreshTokenService } from '../service/refreshToken.service';
import { ActiveSheetObservable } from 'src/app/core/service/observable.service';
import { ToastService } from 'src/app/toast/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private refreshTokenService: RefreshTokenService,
    private activeSheetObservable: ActiveSheetObservable,
    private toastService: ToastService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenService.refreshTokenInProgress.pipe(
      filter(isRefreshing => !isRefreshing),
      take(1),
      switchMap(() => this.handleRequest(request, next))
    );
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the authentication token from a service
    const authToken = window.sessionStorage.getItem('accessToken') || '';

    // Get currently selected sheet id
    const sheetId = this.activeSheetObservable.getActiveSheet();

    // Clone the request and add the token to the 'Authorization' header
    const authRequest = request.clone({ 
      setHeaders: { 
        Authorization: 'Bearer ' + authToken ,
        'x-sheet-id': sheetId
      }
    });

    // Pass the cloned request with the token to the next handler
    return next.handle(authRequest).pipe(
      catchError(error => {
        console.log(`Error at request:`,error);
        if (error instanceof HttpErrorResponse && error.status === 401 && error.error.type == "TokenExpired") {
          // Current accessToken is expired, try to refresh it
          console.log(`Token expired, attempting refresh..`);
          return this.tryTokenRefresh(authRequest, next);
        } else {
          if (error.error && error.error.message) {
            console.log('error', error)
            const errorCode = error.error.statusCode || error.status || ""
            const errorTxt = error.error.error ? `- ${error.error.error}` : ""
            this.toastService.showError(`Error ${errorCode} ${errorTxt}`,error.error.message);
          }
          return throwError(() => new Error((error)));
        }
      })
    );
    
  }

  private tryTokenRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenService.refreshToken().pipe(
      switchMap((token: any) => {
        console.log(`Token refresh successful.`);
        // Repeat the request with updated bearer token
        const clonedRequest = request.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
        return next.handle(clonedRequest);
      }),
      catchError((error) => {
        // Refresh failed, throw the error
        return throwError(() => new Error(error));
      })
    );
  }
}