import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, throwError, of as rxOf } from 'rxjs';
import { catchError, filter, switchMap, take, map } from 'rxjs/operators';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {
  private isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  constructor() {}

  refreshToken(): Observable<string> {
    if (!this.isRefreshing.value) {
      this.isRefreshing.next(true);
      this.refreshTokenSubject.next(null);
      const refreshObservable = from(Auth.currentSession().then(session => session.getAccessToken().getJwtToken()));

      return refreshObservable.pipe(
        switchMap((newToken: string) => {
          this.isRefreshing.next(false);
          console.log(`Access Token has been refreshed.`);
          window.sessionStorage.setItem('accessToken',newToken);
          this.refreshTokenSubject.next(newToken);
          return rxOf(newToken);
        }),
        catchError((error) => {
          this.isRefreshing.next(false);
          console.log(`Could not refresh token: ${error}`);
          this.refreshTokenSubject.next(null);
          return throwError(() => new Error(error));
        })
      );
    } else {
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null), // Filter out null values
            take(1), // Take the first non-null value
            map(token => token as string) // Cast the non-null token to a string
        );
    }
  }
  public get refreshTokenInProgress(): Observable<boolean> {
    return this.isRefreshing.asObservable();
  }
}
