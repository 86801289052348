import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { LayoutModule } from './layout/layout.module';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { PagesModule } from './pages/pages.module';
import { ToastComponent } from "./toast/toast.component";
import { SidebarToggleObservable, ActiveSheetObservable } from './core/service/observable.service';
import { StripeComponent } from './stripe/stripe.component';
import { StripeModule } from './stripe/stripe.module';
import { FilesService } from 'src/app/core/service/files.service';
import { PartialsModule } from 'src/app/partials/partials.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider('807404732453-6cdadhctspei3bmp3m56th55q7f8ve2o.apps.googleusercontent.com')
                }
              ]
            } as SocialAuthServiceConfig,
        },
        SidebarToggleObservable,
        ActiveSheetObservable,
        FilesService
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        HttpClientModule,
        RouterModule,
        AuthModule,
        LayoutModule,
        PartialsModule,
        PagesModule,
        ToastComponent,
        StripeModule,
        SocialLoginModule
    ]
})
export class AppModule { 
  constructor(){
  }
}
