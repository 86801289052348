import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoPopupComponent } from './info-popup/info-popup.component';


@NgModule({
  declarations: [
    InfoPopupComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InfoPopupComponent
  ]
})
export class PartialsModule { }
