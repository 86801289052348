<!-- Container for the whole page -->
<div class="container mt-3">
    <!-- Row to divide the page into two main columns -->
    <div class="row">
        <!-- Left column for buttons, input, and job history table -->
        <div class="col-md-6 p-2">
            <!-- Card for left side content -->
            <div class="card">
                <div class="card-header">Analytics</div>
                <div class="card-body">
                    <h5 class="card-title">Add New Property Id</h5>
                    
                    <input type="text" id="propertyId" class="form-control form-control-sm" placeholder="Enter new Property Id to sync" [(ngModel)]="propertyId" #ctrl="ngModel" required>
                    <button type="button" class="btn btn-primary" (click)="createAnalytics()">
                        Submit
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading.submit"></span>
                    </button>
                </div>
            </div>
        </div>

        <!-- Right column for descriptions or additional content -->
        <div class="col-md-6 p-2">
            <!-- Card for right side content -->
            <div class="card">
                <div class="card-header">Details</div>
                <div class="card-body" *ngIf="gOauthData?.length > 0">
                    <div *ngFor="let _gOauth of gOauthData; let i = index" class="card-text">
                        <span>
                            {{ _gOauth.email }} 
                        </span>
                        <span>
                            is {{ _gOauth.isAuth ? 'Authenticated' : 'not Authenticated' }}
                        </span>
                        <span *ngIf="_gOauth.Id">
                            with Property Id: {{ _gOauth.Id }}
                        </span>
                    </div>
                    <!-- <button type="button" class="btn btn-primary" (click)="syncDatatoS3()" *ngIf="selectedPropertyId">
                        Sync Data to S3
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading.syncToS3"></span>
                    </button> -->

                </div>                
                <div class="card-body" *ngIf="!gOauthData || !gOauthData.length">
                    <h5 class="card-title">Description</h5>
                    <p class="card-text">Link to "talonicairbyte@talonic-app.iam.gserviceaccount.com" this account</p>               
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row p-2">
            <div class="col-md-12" *ngIf="analyticsData">
                <h4></h4>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Job Id</th>
                            <th scope="col">Property Id</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Last Sync Date</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ad of analyticsData">
                            <td>{{ad.job_sync.jobId}}</td>
                            <td>{{ad.property_id}}</td>
                            <td>{{ad.createdOn | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                            <td>{{ad.job_sync.jobStatus}}</td>
                            <td>{{ad.job_sync.lastUpdatedAt  | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                            <td>
                                <i class="bi bi-arrow-clockwise p-2" placement="bottom" ngbTooltip="Synchronize" (click)="syncJob(ad)"></i> 
                                <i class="bi bi-info-circle p-2" placement="bottom" ngbTooltip="Details" (click)="redirectToJobHistory(ad)"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="integration-bottom-nav">
        <div class="integration-return" (click)="back()"><img src="../../../assets/img/icons/chevron.png">Back</div>
    </div>
</div>
