import { Component } from '@angular/core';
import { AIAudioInputObservable } from 'src/app/core/service/observable.service';

@Component({
  selector: 'app-aichataudio',
  templateUrl: './aichataudio.component.html',
  styleUrls: ['./aichataudio.component.scss']
})
export class AIChatAudioComponent {
  recording = false;
  mediaRecorder: MediaRecorder | undefined;
  audioChunks: Blob[] = [];
  recordingTimeout: any;
  recordingStartTime: number = 0;
  recordingInterval: any;
  maxRecordingTime = 20000; // 20 seconds in milliseconds
  minRecordingTime = 500; // 500 milliseconds
  lastPercentage: number = 0;

  constructor(private aiAudioInputObservable: AIAudioInputObservable) {}

  startRecording() {
    if (!this.recording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.start();
          this.recordingStartTime = Date.now();

          this.mediaRecorder.addEventListener('dataavailable', event => {
            this.audioChunks.push(event.data);
          });

          this.mediaRecorder.addEventListener('error', () => {
            this.cleanupRecording();
          });

          this.mediaRecorder.addEventListener('stop', () => {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
            const recordingDuration = Date.now() - this.recordingStartTime;

            if (recordingDuration >= this.minRecordingTime) {
              this.aiAudioInputObservable.add(audioBlob);
            } else {
              console.log('Recording dismissed: too short');
            }

            this.cleanupRecording();
          });

          this.recording = true;

          this.recordingTimeout = setTimeout(() => {
            this.stopRecording();
          }, this.maxRecordingTime);

          this.recordingInterval = setInterval(() => {
            const elapsedTime = Date.now() - this.recordingStartTime;
            const percentage = Math.round(Math.min((elapsedTime / this.maxRecordingTime) * 100, 100));
            if (percentage > this.lastPercentage) {
                console.log(`Now recording ${percentage}%..`);
                //this.aiAudioInputObservable.updateProgress(percentage);
            }
          }, 1000); // Update every second
        });
    }
  }

  stopRecording() {
    if (this.recording) {
      this.mediaRecorder?.stop();
      clearTimeout(this.recordingTimeout);
      clearInterval(this.recordingInterval);
    }
  }

  cleanupRecording() {
    this.audioChunks = [];
    this.recording = false;
    this.recordingStartTime = 0;
    this.lastPercentage = 0;
    clearTimeout(this.recordingTimeout);
    clearInterval(this.recordingInterval);
    if (this.mediaRecorder?.stream) {
      const tracks = this.mediaRecorder.stream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }
}
