import { Component } from '@angular/core';
import lottie from "lottie-web";
import { defineElement } from '@lordicon/element';
defineElement(lottie.loadAnimation);

@Component({
    selector: 'app-failure-stripe',
    templateUrl: './stripe-failure.component.html',
})
export class StripeFailureComponent{
    constructor() { 
    }

}