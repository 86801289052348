import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private currentStepSubject = new BehaviorSubject<number>(0);
  currentStep$ = this.currentStepSubject.asObservable();
  private steps = [
    { element: '#step1', title: 'Step 1', content: 'This is step 1.' },
    { element: '#step2', title: 'Step 2', content: 'This is step 2.' },
    // Add more steps as needed
  ];

  constructor() { }

  startTour() {
    this.currentStepSubject.next(1); // Start the tour with the first step
  }

  nextStep() {
    let currentStep = this.currentStepSubject.value;
    if (currentStep < this.steps.length) {
      this.currentStepSubject.next(++currentStep);
    } else {
      this.endTour(); // End the tour if it's the last step
    }
  }

  previousStep() {
    let currentStep = this.currentStepSubject.value;
    if (currentStep > 1) {
      this.currentStepSubject.next(--currentStep);
    }
  }

  endTour() {
    this.currentStepSubject.next(0); // Reset or end the tour
  }

  getStepInfo(step: number) {
    return this.steps[step - 1]; // Adjust for zero-based index
  }
}
