import { Injectable } from '@angular/core';
import { Subject, Observable, throwError, of, firstValueFrom } from 'rxjs';
import { map, takeUntil, catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../config/config';
import { FileListItem } from '../models/files.model';

@Injectable({
    providedIn: 'root',
})
export class FilesService {

    constructor(
        private http: HttpClient
    ) { }

    fetchImage(image_path: string): Observable<Blob> {
        const url = `${Config.image_url}/${image_path}`;
        return this.http.get(url, { responseType: 'blob' });
    }

    getUploadedFiles(count:number=0,offset:number=0): Observable<FileListItem[]> {
        const url = `${Config.backend_url}/uploads/data`;
        let params = new HttpParams();
        if (count) params.set('count',count);
        if (offset) params.set('offset',offset);
        return this.http.get<FileListItem[]>(url,{params});
    }

}