<div class="auth">

    <div class="auth-logo">
      <img src="../../../assets/img/vhi_logo.png" height="25" />
    </div>

    <div class="full-vp-message">
        <div class="row">
              <lord-icon src="../../../assets/img/icons/confetti.json" trigger="loop"></lord-icon>
            <h1>Thank you for subscribing.</h1>
        </div>
        <div>
            <p>And for taking part in our vision of making working with data a charm.</p>
            <p>Please stay on this page. We will redirect you in few seconds...</p>            
        </div>
    </div>
</div>