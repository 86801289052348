import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AIEvent } from '../../pages/aichat/aichat.model';

@Injectable({
    providedIn: 'root',
})
export class VisibilityObservable {

    private inFocus:boolean = document.hidden;
    private inFocusSubject = new BehaviorSubject<boolean>(this.inFocus);
    public inFocus$ = this.inFocusSubject.asObservable();

    constructor() {
        document.addEventListener("visibilitychange", (e) => {
            if (!document.hidden) {
                // Tab is in focus
                this.inFocus = !document.hidden;
            } else {
                // Tab is inactive
                this.inFocus = !document.hidden;
            }
            this.inFocusSubject.next(this.inFocus);
         });
    }

    isActive() {
        return this.inFocus;
    }
}

@Injectable({
    providedIn: 'root',
})
export class StateObservable {

    private loading:boolean = false;

    constructor() { }

    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    setLoading(loading:boolean=false) {
        this.loadingSubject.next(loading);
    }

}

@Injectable({
    providedIn: 'root',
})
export class AIChatObservable {

    constructor() { }

    private messageSubject: Subject<AIEvent> = new Subject<AIEvent>();

    registerNewUpdate(data:AIEvent) {
        this.messageSubject.next(data);
    }
    
    updates(): Observable<AIEvent>  {
        return this.messageSubject.asObservable();
    }
}

export class SidebarToggleObservable {

    constructor() { }

    private toggledSubject = new BehaviorSubject<boolean>(false);
    public toggled$ = this.toggledSubject.asObservable();

    toggleSidebar() {
        this.toggledSubject.next(!this.toggledSubject.value);
    }

    showSidebar() {
        this.toggledSubject.next(true);
    }

    hideSidebar() {
        this.toggledSubject.next(false);
    }
}

@Injectable({
    providedIn: 'root',
})
export class AIAudioInputObservable {

    constructor() { }

    private audioSubject = new BehaviorSubject<Blob|null>(null);
    public audioSubject$ = this.audioSubject.asObservable();

    clear() {
        this.audioSubject.next(null);
    }

    add(audio:Blob) {
        this.audioSubject.next(audio);
    }

}

export class ActiveSheetObservable {

    constructor() { }

    private sheetSubject = new BehaviorSubject<string>('');
    private tableSubject = new BehaviorSubject<string>('');
    public sheetId$ = this.sheetSubject.asObservable();
    public tableId$ = this.tableSubject.asObservable();

    setActiveSheet(sheetId:string='',tableId:string='') {
        console.log(`Sheet is now '${sheetId}' (Table: '${tableId}')`);
        this.sheetSubject.next(sheetId);
        this.tableSubject.next(tableId);
    }

    getActiveSheet():string {
        return this.sheetSubject.value;
    }

    getActiveTable():string {
        return this.tableSubject.value;
    }

    getActive():{ sheetId: string; tableId: string; } {
        return { sheetId: this.sheetSubject.value, tableId: this.tableSubject.value };
    }

}

@Injectable({
    providedIn: 'root',
})
export class SidebarItemCollapseObservable {

    constructor(
        private sideBarToggleObservable: SidebarToggleObservable
    ) {
        this.toggleSubject.asObservable().subscribe(val=>{
            this.toggleItem(val);
        });
        this.sideBarToggleObservable.toggled$.subscribe(val=>{
            this.toggleSubject.next("");
        });
    }

    private toggleItem(item:'workspaces' | 'dataspace' | 'integrations' | '') {
        if (item == this.activeSubject.value) {
            this.activeSubject.next("");
            this.showSubject.next(false);
        } else {
            this.activeSubject.next(item);
            this.showSubject.next(true);
        }
    }

    private activeSubject = new BehaviorSubject<'workspaces' | 'dataspace' | 'integrations' | ''>('');
    private showSubject = new BehaviorSubject<boolean>(false);

    public activeItem$ = this.activeSubject.asObservable();
    public show$ = this.showSubject.asObservable();
    public toggleSubject = new BehaviorSubject<'workspaces' | 'dataspace' | 'integrations' | ''>('');

}