<div class="auth">

  <div class="auth-logo">
    <img src="../../../assets/img/vhi_logo.png" height="25" />
  </div>

<div>
  <fieldset>
    <div class="row justify-content-center my-5">
      <div class="col-4">
        <div>
          <h3 class="">Create your free account</h3>
        </div>
        <div class="" *ngIf="isSignUpContinue.first">
          <div class="card-body" >
            <form [formGroup]="userForm">
              <div>
                <div class="text-danger">
                  <div>
                    {{errorMsg}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-2">
                  <label for="email" class="form-label">Work Email:</label>
                  <input type="email" id="email" name="email" formControlName="email"
                    class="form-control form-control-sm" (input)="errorMsg = ''">
                    <div *ngIf="userForm.status == 'INVALID' && (userForm.dirty || userForm.touched)" class="text-danger">
                      <div *ngIf="userForm.controls['email'].hasError('required')">Email is required.</div>
                      <div *ngIf="userForm.controls['email'].hasError('email')">Invalid email address.</div>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col d-grid">
                  <button type="button" (click)="signUpContinue('second')" class="btn btn-sm btn-success branded-col"
                    [disabled]="loading || userForm.status == 'INVALID'">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                      *ngIf="loading"></span>
                    Continue
                  </button>
                </div>
              </div>
            </form>
            <!--
            <div class="row">
              <div class="col">
                <hr class="my-4">
              </div>
              <div class="col-auto">
                <span class="text-muted">Or</span>
              </div>
              <div class="col">
                <hr class="my-4">
              </div>
            </div>
            <div class="row">
              <button type="button" class="btn btn-outline-dark" (click)="signUpwithSSO()">Continue with Single Sign
                On</button>
            </div>
            <div class="row">
              <button type="button" class="btn btn-outline-dark" (click)="signUpwithGoogle()">Contine with
                Google</button>
            </div>
            <div class="row">
              <div class="col d-grid">
                <a href="">Continue with Apple ID</a>
              </div>
            </div>
          -->
            <div class="row">
              <div class="col d-inline fineprint">
                By creating an account, you agree to the <a href="">Terms of Service</a> and <a href="">Privacy
                  Policy</a>
              </div>
            </div>
            <div class="row">
              <div class="col d-inline sign-in-offer">
                Already have an Account? <a href="" [routerLink]="['/signIn']"> Sign in</a>
              </div>
            </div>
          </div>
        </div>
        <div class="" *ngIf="isSignUpContinue.second">
          <div class="card-body" *ngIf="!isConfirm">
            <div class="row">
              <div class="col mb-2">
                <label for="name" class="form-label">Full Name:</label>
                <input type="text" id="name" name="name" #name="ngModel" [(ngModel)]="user.name"
                  class="form-control form-control-sm" autocomplete="off">
              </div>
            </div>
            <div class="row">
              <div class="col mb-2">
                <label for="password" class="form-label">Password:</label>
                <div class="input-group input-group-sm">
                  <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password"
                    #password="ngModel" [(ngModel)]="user.password" class="form-control form-control-sm" (keypress)="passwordValidator()">
                  <button type="button" class="btn btn-outline-secondary"
                    (click)="user.showPassword = !user.showPassword">
                    <i class="bi"
                      [ngClass]="{'bi-eye-fill': !user.showPassword, 'bi-eye-slash-fill': user.showPassword}"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <span>
                Signing up as {{userForm.value.email}} <a href="" [routerLink]="['/signIn']"
                  (click)="isSignUpContinue.first = true;">Sign Out</a>
              </span>
            </div>
            <div class="">
              <span class="width_50">
                <ngb-progressbar type="success" [value]="25"></ngb-progressbar>
              </span>
              <button type="button" (click)="signUpContinue('third');" class="btn btn-sm btn-success branded-col"
                [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                Continue
              </button>
            </div>
          </div>
          <div class="card-body" *ngIf="isConfirm">
            <h5>You just received your access code via email. Please enter it below to validate your address.</h5>
            <div class="row">
              <div class="col mb-2">
                <label for="code" class="form-label">Code:</label>
                <input type="text" id="code" name="code" #code="ngModel" [(ngModel)]="user.code" class="form-control form-control-sm">
              </div>
            </div>
            <div class="row">
              <div class="col d-grid">
                <button type="button" (click)="confirmSignUp('third')" class="btn btn-sm btn-success branded-col" [disabled]="loading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="" *ngIf="isSignUpContinue.third">
          <div class="card-body">
            <h3>In which team are you?</h3>
            <h5>This helps us to enhance our user experience for you</h5>
            <button type="button" class="btn btn-outline-dark" *ngFor="let _team of teams"
              (click)="saveTeam(_team)" [class.active]="this.user.team === _team.name">{{_team.name}}</button>
          </div>
          <div class="">
            <span class="width_50">
              <ngb-progressbar type="success" [value]="50"></ngb-progressbar>
            </span>
            <div class="row">
              <button type="button" (click)="signUpContinue('forth')" class="btn btn-sm btn-success branded-col" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                Continue
              </button>
              <div (click)="signUpContinue('forth')" class="skip">
                <a>
                  Skip
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="" *ngIf="isSignUpContinue.forth">
          <div class="card-body">
            <h3>What is your role?</h3>
            <h5>This helps us to enhance our user experience for you</h5>
            <button type="button" class="btn btn-outline-dark" *ngFor="let _role of roles"
              (click)="saveRole(_role)"  [class.active]="this.user.role === _role.name" >{{_role.name}}</button>
          </div>
          <div class="">
            <span class="width_50">
              <ngb-progressbar type="success" [value]="75"></ngb-progressbar>
            </span>
            <div class="row">
              <button type="button" (click)="signUpContinue('login')" class="btn btn-sm btn-success branded-col" [disabled]="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                Continue
              </button>
              <div (click)="signUpContinue('login')" class="skip">
                <a>
                  Skip
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>

</div>