<div class="d-flex profile-header">
  <div>
    <button type="button" class="btn btn-primary" routerLink="/"><img src="../../../assets/img/icons/chevron.png"/>Back</button>
  </div>
</div>
<div class="profile">
  <h3>Account overview</h3>
  <div class="profile-block row">
      <div class="user-profile-data">
        <div class="user-avatar">
          {{getUserInitial()}}
        </div>
        <div>
          <div class="">
            <label for="email" class="form-label">Email:</label>
            <input type="email" id="email" name="email" #email="ngModel" [ngModel]="user.email" disabled
              class="form-control form-control-sm">
          </div>
          <div class="">
            <label for="name" class="form-label">Name:</label>
            <input type="text" id="name" name="name" #name="ngModel" [(ngModel)]="user.name"
              class="form-control form-control-sm">
          </div>
        </div>
      </div>
        <div>
          <div>
            <h3>Plan</h3>
            Individual Plan <br>
            <a href="https://www.talonic.ai/pricing">Change Plan</a>
            
          </div>
          <div>
            <h3>Credits</h3>
            100 / 1000 Used<br>
            Buy Credits
          </div>
        </div>
  </div>
  <!--Refer friends<br>
  Get €10 in AI credits for every person you refer to Talonic
  </div>-->

  <h3>Organisation</h3>
  <div class="profile-block row">
    <div class="">
      <label for="name" class="form-label">Organisation:</label>
      <input type="text" id="organisation-name" class="form-control form-control-sm">
      Invite Members
    </div>
    <div class="">
      <label for="name" class="form-label">Billing address</label>
      <div class="billing-address">
        Organisation Name<br>
        Organisation Address<br>
        Zip Code + City<br>
        Country<br>
        VAT-ID<br>
        Change billing address
      </div>
    </div>
  </div>

  <!--<div class="profile-block">
    <h3>Members</h3>
    Team - X Members
    <div class="row">
      <div>All members</div>
      <div>Pending invites</div>
      <div>Invite member</div>
    </div>
    <table>
      <thead>
        <tr>
          <td>Name</td>
          <td>Email</td>
          <td>Role</td>
          <td>...</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Nikolas Adamopoulos</td>
          <td>nikolas@talonic.ai</td>
          <td>Admin</td>
          <td>...</td>
        </tr>
      </tbody>
    </table>
  </div>-->

  <!--<div class="profile-block">
    <h3>Referrals</h3>
    <p>Get €10 in AI credit for every person you refer to Talonic. Referring someone below will not invite them to your workspace or share any of your data with them. 
      If you'd like to collaborate with someone, you can share your workspace with them. You'll receive credit for both referrals and collaborators once they sign up and verify their email address.
      Your credit is automatically converted into AI credits and can be used immediately.</p>
    <label>Your referral link</label>
    <div class="referral-link">
      https://app.talonic.ai/invite/12345678
    </div>
    <label>Email an invite</label>
    <div class="row">
      <input type="text">
      <button>Send invite</button>
    </div>
    <label>Invites</label>
    <div class="row">
      <div>
        <div>Nikolas Adamopoulos</div>
        <div>nikolas@talonic.ai</div>
      </div>
      <div>
        <div>Credit awarded for invite</div>
        <div>4 days ago</div>
      </div>
      <div>
        <div>Email verification pending</div>
        <div>Invite send 4 days ago</div>
      </div>
    </div>-->
</div>