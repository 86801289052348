import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(sessionStorage.getItem('user')) {
            return true;
        }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/signIn'], { queryParams: { returnUrl: state.url } });
    return false;
    }
}

