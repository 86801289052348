import { Injectable } from '@angular/core';
import { Config } from '../config/config';
import { OscillatorTune, OscillatorSound } from '../models/sound.model';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  constructor() { }

  private playMelody(melody:OscillatorTune) {
    try {
      const audioCtx = new AudioContext();

      // Create a gain node to control the volume
      const gainNode = audioCtx.createGain();
      gainNode.gain.value = 0.5;

      // Set the start time to be a small delay after the current time
      const startTime = audioCtx.currentTime + 0.1;

      // Play the selected melody
      melody.forEach((note, index) => {
        // Create an oscillator node for the note
        const oscillator = audioCtx.createOscillator();
        oscillator.type = note.type;
        oscillator.frequency.value = note.frequency;

        // Connect the oscillator to the gain node and the destination
        oscillator.connect(gainNode);
        gainNode.connect(audioCtx.destination);

        if (note.gain) {
          gainNode.gain.value = note.gain;
        }

        // Start the oscillator at the correct time
        oscillator.start(startTime + note.duration);

        // Stop the oscillator after the note duration has passed
        oscillator.stop(startTime + note.duration + 0.1);
      });
    } catch(e) {}
  }

  playAIDone() {
    return;
    const melody:OscillatorTune = [
      { type: 'sine', frequency: 220, duration: 0 },
      { type: 'sine', frequency: 440, duration: 0.2 },
    ];
    this.playMelody(melody);
  }

}
