import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { StripeComponent } from './stripe.component';
import { StripeService } from './stripe.services';
import { StripeRoutingModule } from './stripe-routing.component';
import { UserService } from '../core/service/user.service';
import { StripeSuccessComponent } from './success/stripe-success.component';
import { StripeFailureComponent } from './failure/stripe-failure.component';

@NgModule({
  declarations: [
    StripeComponent,
    StripeSuccessComponent,
    StripeFailureComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    RouterModule,
    StripeRoutingModule
  ],
  providers: [
    StripeService, UserService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class StripeModule { }
