import { Component, Input, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
    selector: 'job-history',
    templateUrl: './job-history.component.html'
})

export class JobHistoryComponent implements OnInit {

    jobHistory: any;
    id: string = '';
    type: string = '';
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService,
        private toastService: ToastService
    ) {
    }


    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.id = params['id'];
            this.type = params['type']
            this.getJobHistory();
        });
    }

    async getJobHistory() {
        this.airbyteService.getJobHistory(this.id, this.type)
            .subscribe({
                next: (response: any) => {
                    console.log(response)
                    this.jobHistory = response.jobs;
                    return this.jobHistory.sort((a: any, b: any) => {
                        return new Date(b.lastUpdatedAt).getTime() - new Date(a.lastUpdatedAt).getTime();
                    });
                }
            })
    }




    checkJobStatus(jobId: number) {
        this.airbyteService.syncJobStatus(jobId).subscribe({
            next: (res: any) => {
                this.jobHistory.map((job: any, index: number) => {
                    if (job.jobId === jobId) {
                        this.jobHistory[index].jobStatus = res.data.status;
                        this.jobHistory[index].lastUpdatedAt = res.data.lastUpdatedAt;
                        this.toastService.showSuccess('success', `job status is : ${res.data.status}`)
                    }
                });
            }
        });
    }

}
