import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
    selector: 'analytics-integration',
    templateUrl: './analytics.component.html'
})

export class GoogleAnalyticsComponent implements OnInit {

    propertyId: string = "";
    jobHistory: any;
    loading = {
        submit: false,
        syncToS3: false
    };
    gOauthData: any;
    analyticsData: any
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService,
        private toastService: ToastService
    ) {
    }


    ngOnInit(): void {
        this.getAirbyteData();
        // this.getOAuthUser();

    }

    getOAuthUser() {
        this.googleService.getOAuthUser().subscribe({
            next: (res: any) => {
                console.log('res', res)
                this.gOauthData = res.data;
            }
        })
    }


    getAirbyteData() {
        this.airbyteService.getAirbyteData('analytics')
            .subscribe({
                next: (response: any) => {
                    const result = response.data.reduce((acc: any, item: any) => {
                        const existingItem = acc[item.property_id];
                        if (!existingItem || new Date(item.modifiedOn) > new Date(existingItem.modifiedOn)) {
                            acc[item.property_id] = item;
                        }
                        return acc;
                    }, {});

                    // Convert the result object back to an array
                    this.analyticsData = Object.values(result);
                }
            })
    }


    createAnalytics() {
        this.loading.submit = true;
        this.airbyteService.createAnalytics(this.propertyId)
            .subscribe({
                next: (response: any) => {
                    this.getAirbyteData();
                    this.loading.submit = false;
                    this.toastService.showSuccess('success', response.message)
                }, error: () => {
                    this.loading.submit = false;
                }
            });
    }

    syncJob(analyticData:any){
        this.airbyteService.syncJobStatus(analyticData.job_sync.jobId)
        .subscribe((response:any) => {
            this.analyticsData.map((_ad:any) => {
                if(_ad.job_sync.jobId === response.data.jobId){
                    this.toastService.showSuccess('success', `job status is : ${response.data.status}`)
                }
                _ad.job_sync.lastUpdatedAt = response.data.lastUpdatedAt
                _ad.job_sync.jobStatus = response.data.status;
                _ad.job_sync.jobId = response.data.jobId;
            })
            if(response.data.status === 'succeeded') {
                //this.syncDatatoS3(analyticData.property_id);
            }
        })
    }

    syncDatatoS3(propertyId: string) {
        this.loading.syncToS3 = true;
        this.airbyteService.syncDataToS3(propertyId)
            .subscribe({
                next: (res) => {
                    this.loading.syncToS3 = false;
                    this.toastService.showSuccess('success', 'Data is successfully synced to s3');
                }
            })
    }

    redirectToJobHistory(analyticData:any){
        this.router.navigate([`/job-history/analytics/${analyticData.property_id}`]);
    }

    back() {
        this.router.navigate(["/"]);
    }

}

