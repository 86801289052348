import { Component, OnInit } from '@angular/core';

import { IUser, CognitoService } from '../../core/service/cognito.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  loading: boolean;
  user: IUser;

  constructor(private cognitoService: CognitoService) {
    this.loading = false;
    this.user = {} as IUser;
  }

  public ngOnInit(): void {
    this.cognitoService.getUser()
    .then((user: any) => {
      this.user = user.attributes;
    });
  }

  public update(): void {
    this.loading = true;

    this.cognitoService.updateUser(this.user)
    .then((res) => {
      window.sessionStorage.setItem('user', JSON.stringify(this.user));
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }

  getUserInitial() {
    return this.cognitoService.getLoggedInUserName()[0].toUpperCase();
  }

}
