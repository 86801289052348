<div class="row p-2" *ngIf="jobHistory && jobHistory.length">
    <div class="col-md-12">
        <h4>Job History List</h4>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Job Id</th>
                    <th scope="col">Start Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Type</th>
                    <th scope="col">End Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let _jh of jobHistory">
                    <td>{{_jh.jobId}}</td>
                    <td>{{_jh.createdOn | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                    <td>{{_jh.jobStatus}}</td>
                    <td>{{_jh.jobType}}</td>
                    <td>{{_jh.lastUpdatedAt  | date: 'dd-MM-yyyy, HH:mm:ss'}}</td>
                    <td *ngIf="_jh.jobStatus === 'pending' || _jh.jobStatus === 'running'" (click)="checkJobStatus(_jh.jobId)">Refresh</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>