<div class="chat-message-info" *ngIf="message">
    <button class="info-button" #infoButton (click)="toggleInfo()">
        <lord-icon src="../../../assets/img/icons/info.json" trigger="hover"></lord-icon>
    </button>
    <div class="chat-info-content" #contentContainer *ngIf="isVisible" >
        <div class="chat-info-timestamp">Completed at {{getTime()}}</div>
        <div class="chat-info-sources" *ngIf="message.sources">
            <div>Data processed for this response:</div>
            <ul class="chat-info-sources-list">
                <li *ngFor="let sheet of message.sources">
                    <a (click)="openSheet(sheet.sheetId)">
                        <div>{{sheet.sheetName}}</div>
                        <div>
                            <div class="data-source-type-icon" *ngIf="sheet.type=='dataspace'" [ngClass]="sheet.sourceType"></div>
                            <div class="data-source-type-icon workspace" *ngIf="sheet.type=='workspace'"></div>
                            <div>{{sheet.tableName}}</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="chat-info-correlation-id"><app-info-popup>Correlation ID<span popupData>The Correlation ID is a randomly generated number that...</span></app-info-popup>: <span>{{message.correlationId}}</span></div>
    </div>
</div>