<div id="content-wrapper" 
    [ngClass]="{
        'loading':isLoading,
        'idle':!isLoading,
        'sidebar-on':sidebarToggled,
        'sidebar-off':!sidebarToggled,
        'workspaces-on': (activeSidebarItem=='workspaces'),
        'dataspace-on': (activeSidebarItem=='dataspace'),
        'integrations-on': (activeSidebarItem=='integrations'),
    }">
    <app-header></app-header>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>


