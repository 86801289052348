import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StripeSuccessComponent } from './success/stripe-success.component';
import { StripeFailureComponent } from './failure/stripe-failure.component';
import { StripeComponent } from './stripe.component';

const routes: Routes = [
  {
    path: 'stripe-success',
    component: StripeSuccessComponent,
  },
  {
    path: 'stripe-failure',
    component: StripeFailureComponent,
  },
  {
    path: 'stripe',
    component: StripeComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class StripeRoutingModule {
}
