import { Component, OnInit } from '@angular/core';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { GoogleService } from 'src/app/core/service/google.service';
import { AirByteService } from 'src/app/core/service/airbyte.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/toast/toast.service';

@Component({
    selector: 'woocom-integration',
    templateUrl: './woocom.component.html'
})

export class WooComComponent implements OnInit {

    woocomForm!: FormGroup;
    woocomData: any;
    loading = false;
    constructor(
        private authService: SocialAuthService,
        private route: ActivatedRoute,
        private router: Router,
        private googleService: GoogleService,
        private airbyteService: AirByteService,
        private formBuilder: FormBuilder,
        private toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        this.woocomForm = this.formBuilder.group({
            api_key: ['', Validators.required],
            api_secret: ['', Validators.required],
            shopName: ['', Validators.required],
            startDate: [ '']  // Default to today's date, in YYYY-MM-DD format
        });
        this.getWoocom()
    }

    createWoocom() {
        if (this.woocomForm.valid) {
            this.loading = true;
            this.airbyteService.createWoocom(this.woocomForm.value)
                .subscribe((res: any) => {
                    console.log(res)
                    this.loading = false;
                })
        }
    }

    getWoocom() {
        this.airbyteService.getAirbyteData('woocommerce')
            .subscribe({
                next: (response: any) => {
                    const result = response.data.reduce((acc: any, item: any) => {
                        const existingItem = acc[item.shop];
                        if (!existingItem || new Date(item.modifiedOn) > new Date(existingItem.modifiedOn)) {
                            acc[item.shop] = item;
                        }
                        return acc;
                    }, {});

                    // Convert the result object back to an array
                    this.woocomData = Object.values(result);
                }
            })
    }

    syncJob(analyticData: any) {
        this.airbyteService.syncJobStatus(analyticData.job_sync.jobId)
            .subscribe((response: any) => {
                this.woocomData.map((_ad: any) => {
                    if (_ad.job_sync.jobId === response.data.jobId) {
                        _ad.job_sync.lastUpdatedAt = response.data.lastUpdatedAt
                        _ad.job_sync.jobStatus = response.data.status;
                        this.toastService.showSuccess('success', `job status is : ${response.data.status}`)
                    }
                })
                if (response.data.status === 'succeeded') {
                    this.syncDatatoS3(analyticData.property_id);
                }
            })
    }

    syncDatatoS3(propertyId: string) {
        this.airbyteService.syncDataToS3(propertyId)
            .subscribe({
                next: (res) => {
                    this.toastService.showSuccess('success', 'Data is successfully synced to s3');
                }
            })
    }

    redirectToJobHistory(_woocomData: any) {
        this.router.navigate([`/job-history/woocommerce/${_woocomData.shop}`]);
    }

}
